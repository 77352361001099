import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import warning from '../../../../../assets/icons/exclamation-mark-red.png';
import WrappedParagraph from 'components/Wrapped/Paragraph';
// import close from '../../../../assets/icons/close.png';

const Rejected = ({ t: translate }) => {
  const { documents } = useSelector(({ user }) => user.profile);

  const t = nestedTranslate(translate, 'account.onboarding.documents');

  return (
    <>
      <Box
        margin={{ vertical: 'medium' }}
        pad="small"
        direction="row"
        justify="start"
        align="center"
        border={{ color: '#e1554b', size: 'small' }}
      >
        <Box width={{ min: '2rem' }} margin={{ right: 'small' }}>
          <img src={warning} alt="warning" />
        </Box>
        <Box direction="column">
          <WrappedParagraph >
            {t('error.rejected')}
          </WrappedParagraph>
          {documents.map(
            doc =>
              doc.is_revision_requested && (
                <Box
                  direction="row"
                  align="center"
                  margin={{ top: 'xsmall' }}
                  key={doc.id}
                >
                  <WrappedParagraph
                    level={4}
                    margin={{ right: 'small' }}
                    style={{ textDecoration: 'underline' }}
                  >{`${doc.filename}`}</WrappedParagraph>
                  <WrappedParagraph level={4}>{doc.notes}</WrappedParagraph>
                </Box>
              ),
          )}
        </Box>
      </Box>
    </>
  );
};

export default withNamespaces()(Rejected);
