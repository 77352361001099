var IOTA = require('@iota/validators');

function isValidIotaAddress(address, currency, networkType) {
  var isValid = IOTA.isAddress(address);
  return isValid;
}

module.exports = {
  isValidAddress: function(address, currency, networkType) {
    return isValidIotaAddress(address, currency, networkType);
  },
};
