export default {
  lg: [
    {
      h: 61,
      w: 8,
      x: 0,
      y: 0,
      componentList: ['TVChartContainer', 'DepthChart'],
      title: 'exchange.chart',
    },
    {
      h: 45,
      w: 8,
      x: 0,
      y: 61,
      componentList: ['OpenOrders', 'OrderHistory', 'Portfolio'],
    },
    {
      h: 61,
      w: 2,
      x: 8,
      y: 0,
      componentList: ['OrderBook'],
      title: 'exchange.orderBook',
      hasHeading: true,
    },
    {
      h: 61,
      w: 2,
      x: 10,
      y: 0,
      componentList: ['TradeHistory'],
      title: 'exchange.tradeHistory',
      hasHeading: true,
    },
    {
      h: 45,
      w: 4,
      x: 8,
      y: 61,
      componentList: ['CreateOrder'],
    },
  ],
  md: [
    {
      h: 50,
      w: 12,
      x: 0,
      y: 0,
      componentList: ['TVChartContainer', 'DepthChart'],
      title: 'exchange.chart',
    },
    {
      h: 40,
      w: 12,
      x: 0,
      y: 91,
      componentList: ['OpenOrders', 'OrderHistory', 'Portfolio'],
    },
    {
      h: 41,
      w: 3,
      x: 0,
      y: 50,
      componentList: ['OrderBook'],
      title: 'exchange.orderBook',
      hasHeading: true,
    },
    {
      h: 41,
      w: 3,
      x: 3,
      y: 50,
      componentList: ['TradeHistory'],
      title: 'exchange.tradeHistory',
      hasHeading: true,
    },
    {
      h: 41,
      w: 6,
      x: 6,
      y: 50,
      componentList: ['CreateOrder'],
    },
  ],
  sm: [
    {
      h: 33,
      w: 1,
      x: 0,
      y: 0,
      componentList: ['TVChartContainer', 'DepthChart'],
      isDraggable: false,
      isResizable: false,
    },
    {
      h: 33,
      w: 1,
      x: 0,
      y: 33,
      componentList: ['OrderBook', 'TradeHistory', 'OpenOrders', 'Portfolio'],
      isDraggable: false,
      isResizable: false,
    },
    {
      h: 71,
      w: 1,
      x: 0,
      y: 66,
      componentList: ['CreateOrder'],
      isDraggable: false,
      isResizable: false,
    },
  ],
};
