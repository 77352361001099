import React, { useEffect, useState } from 'react';
import { get, has } from 'lodash';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trans, withNamespaces } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { exchangeApi } from 'api';
import { Form } from 'components/Form';
import { Box, Text, Button } from 'components/Wrapped';
import { SimplexCurrencySelect } from 'pages/Simplex';
import { formatNumberToPlaces, nestedTranslate } from 'utils';
import { BoxHeading, ConditionalWrapper } from 'components/Helpers';
import { Requires2FA } from 'containers/Requires2FA';
import { triggerToast } from 'redux/actions/ui';
import {
  buildCurrencies,
  calculatePayment,
  checkIfAssetTradable,
  filterNonAllowedAssets,
  getSelectValue,
  isTradingPairLoaded,
  sortAllowedPaymentCurrencies,
} from 'containers/Tables/utils';
import InstaTradeFieldsV2 from './InstaTradeFieldsV2';

const InstaTradeV2Form = ({
  t: translate,
  tradingPairsByCurrency,
  gAuthEnabled,
  isAuthenticated,
  portfolios,
}) => {
  const t = nestedTranslate(translate, 'instaTrade');

  const { replace } = useHistory();
  const [mutate] = useMutation(values => exchangeApi.submitInstaTrade(values));
  const { currency: matchCurrency, payment: matchPayment } = useParams();

  const [hasInitialized, setHasInitialized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [receiveCurrency, setReceiveCurrency] = useState(matchCurrency);
  const [paymentCurrency, setPaymentCurrency] = useState(matchPayment);
  const [tradingPair, setTradingPair] = useState({});
  const [receiveCurrenciesSelect, setReceiveCurrencies] = useState();
  const [paymentCurrenciesSelect, setPaymentCurrencies] = useState();
  const [isReceiveCurrencyTradable, setIsReceiveCurrencyTradable] = useState(
    true,
  );
  const [tradablePairByModulus, setTradablePairByModulus] = useState(true);

  const balances = {
    receiveCurrency: get(portfolios, `${receiveCurrency}.balance`, 0),
    paymentCurrency: get(portfolios, `${paymentCurrency}.balance`, 0),
  };

  const checkIfReceiveCurrencyTradable = () => {
    const isTradable = checkIfAssetTradable(receiveCurrency);
    setIsReceiveCurrencyTradable(isTradable);
  };

  //Sets receiveCurrencies +  receiveCurrency + hasInitialized
  const setReceiveCurrenciesDefaultState = () => {
    const currencies = Object.keys(tradingPairsByCurrency);
    let newReceieveCurrencies = buildCurrencies(currencies);

    //trustCo logic
    newReceieveCurrencies = filterNonAllowedAssets(newReceieveCurrencies);

    setReceiveCurrencies(newReceieveCurrencies);

    setHasInitialized(true);

    if (!receiveCurrency || !has(tradingPairsByCurrency, receiveCurrency))
      setReceiveCurrency(currencies[0]);
  };

  //Sets receiveCurrencies +  tradingPair  + tradablePairByModuls
  const refreshReceiveCurrencies = () => {
    if(!tradingPairsByCurrency?.[paymentCurrency]) return;
    const currencies = Object.keys(tradingPairsByCurrency?.[paymentCurrency]);
    let newReceieveCurrencies = buildCurrencies(currencies);

    newReceieveCurrencies = filterNonAllowedAssets(newReceieveCurrencies);

    setReceiveCurrencies(newReceieveCurrencies);

    const newTradingPair =
      tradingPairsByCurrency?.[receiveCurrency]?.[paymentCurrency];

    if (newTradingPair) {
      setTradingPair(newTradingPair);
      setTradablePairByModulus(true);
    } else setTradablePairByModulus(false);
  };

  const areParamsForbidden = () =>
    receiveCurrency !== matchCurrency || paymentCurrency !== matchPayment;

  useEffect(() => {
    // [ONLY FIRST LOAD]
    if (!hasInitialized) return setReceiveCurrenciesDefaultState();

    // [FROM SECOND LOAD] - sets receiveCurrencies
    if (isTradingPairLoaded(tradingPair) && paymentCurrency) {
      refreshReceiveCurrencies();
    }
  }, [
    hasInitialized,
    receiveCurrency,
    paymentCurrency,
    tradingPairsByCurrency,
    isReceiveCurrencyTradable,
  ]);

  useEffect(() => {
    const allowedPaymentCurrencies = sortAllowedPaymentCurrencies(
      tradingPairsByCurrency,
    );

    let newPaymentCurrencies = buildCurrencies(allowedPaymentCurrencies);

    setPaymentCurrencies(newPaymentCurrencies);

    if (!receiveCurrency) return;

    if (!paymentCurrency) {
      const currencies = Object.keys(
        tradingPairsByCurrency?.[receiveCurrency],
      ).sort();

      const newPayment = currencies[0];

      setPaymentCurrency(newPayment);

      return;
    }

    checkIfReceiveCurrencyTradable();

    const newTradingPair =
      tradingPairsByCurrency?.[receiveCurrency]?.[paymentCurrency];

    if (!newTradingPair) {
      setPaymentCurrency(
        Object.keys(tradingPairsByCurrency?.[receiveCurrency]).sort()[0],
      );
      return;
    }

    setTradingPair(newTradingPair);

    if (areParamsForbidden()) {
      replace({
        pathname: generatePath('/insta-trade/:currency?/:payment?', {
          currency: receiveCurrency,
          payment: paymentCurrency,
        }),
      });
    }
  }, [
    tradingPair,
    receiveCurrency,
    // paymentCurrency,
    replace,
    tradingPairsByCurrency,
    isReceiveCurrencyTradable,
  ]);

  const validationSchema = () => {
    return Yup.object().shape({
      paymentAmount: Yup.number()
        .required()
        .min(tradingPair.minLimit)
        .max(tradingPair.maxLimit),
    });
  };

  const currencyValue = calculatePayment({
    value: 1,
    rate: tradingPair?.rate,
    commission: tradingPair?.commission,
  });

  const handleSubmit = async ({ values, resetForm }) => {
    try {
      setIsSubmitting(true);
      setError();
      setSuccess();
      const submitData = {
        baseCurrency: paymentCurrency,
        quoteCurrency: receiveCurrency,
        baseAmount: values?.paymentAmount,
      };
      const data = await mutate(submitData);

      setIsSubmitting(false);

      if (data.status === 'Success') {
        resetForm();
        triggerToast(data.message, 'success', 2500);
      } else if (data.status === 'Error') {
        setError(data.message);
      }
    } catch (e) {}
  };

  return (
    <ConditionalWrapper wrapper={<Requires2FA />} enableWrapper={gAuthEnabled}>
      <BoxHeading background="background-3">{t('title')}</BoxHeading>
      <Box background="background-2" gap="small">
        {tradablePairByModulus && (
          <Box pad="none">
            <Text>{t('estimate', { currency: receiveCurrency })}</Text>
            <Box pad="none" direction="row" align="end" gap="xsmall">
              <Text size="large">{formatNumberToPlaces(currencyValue)}</Text>
              <Text>{paymentCurrency}</Text>
            </Box>
          </Box>
        )}
        <Box direction="row" pad="none" gap="small" align="center">
          <Text>{t('youHave')}</Text>
          {paymentCurrency && (
            <Box pad="xsmall" background="background-3">
              <Text size="xsmall">
                {formatNumberToPlaces(balances?.paymentCurrency)}{' '}
                {paymentCurrency}
              </Text>
            </Box>
          )}
          {receiveCurrency && (
            <Box pad="xsmall" background="background-3">
              <Text size="xsmall">
                {formatNumberToPlaces(balances?.receiveCurrency)}{' '}
                {receiveCurrency}
              </Text>
            </Box>
          )}
        </Box>
        <Formik
          initialValues={{
            paymentAmount: '',
            receiveAmount: '',
            // gauth: '',
          }}
          validationSchema={validationSchema()}
          onSubmit={(values, { resetForm }) =>
            handleSubmit({ values, resetForm })
          }
        >
          <Form gap="small">
            <InstaTradeFieldsV2
              tradingPair={tradingPair}
              tradablePairByModulus={tradablePairByModulus}
              t={t}
              paymentSelect={
                <SimplexCurrencySelect
                  options={paymentCurrenciesSelect || null}
                  value={getSelectValue(paymentCurrency)}
                  onCurrencySelected={setPaymentCurrency}
                />
              }
              receiveSelect={
                <SimplexCurrencySelect
                  options={receiveCurrenciesSelect || null}
                  value={getSelectValue(receiveCurrency)}
                  onCurrencySelected={setReceiveCurrency}
                />
              }
            />

            {error && (
              <Text color="status-error">{translate(`messages.${error}`)}</Text>
            )}
            {success && (
              <Text color="status-success">
                {translate(`messages.${success}`)}
              </Text>
            )}
            {isReceiveCurrencyTradable &&
              tradablePairByModulus &&
              (isAuthenticated ? (
                <Button
                  type="submit"
                  color="primary"
                  fill="horizontal"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('buy', { currency: receiveCurrency })}
                </Button>
              ) : (
                <Box
                  pad="small"
                  background="background-3"
                  gap="xsmall"
                  direction="row"
                  justify="center"
                >
                  <Text>
                    <Link to="/login">{translate('generics.login')}</Link>{' '}
                  </Text>
                  <Text>{translate('generics.or')}</Text>
                  <Text>
                    <Link to="/signup">{translate('generics.register')}</Link>
                  </Text>
                </Box>
              ))}
          </Form>
        </Formik>
        {isAuthenticated && (
          <Text>
            <Trans i18nKey="instaTrade.history">
              {' '}
              <Link to="/orders/insta-trades"> </Link>
            </Trans>
          </Text>
        )}
      </Box>
    </ConditionalWrapper>
  );
};

const mapStateToProps = ({
  exchangeSettings: { currencySettings },
  auth: { isAuthenticated, gAuthEnabled },
  portfolio: { portfolios },
}) => ({
  currencySettings,
  isAuthenticated,
  portfolios,
  gAuthEnabled,
});

export default withNamespaces()(connect(mapStateToProps)(InstaTradeV2Form));
