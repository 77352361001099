import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { InProgress } from 'grommet-icons';
import { KYCForm } from 'pages/Account';
import { withNamespaces } from 'react-i18next';
import { Heading, Box, Paragraph } from 'components/Wrapped';
import { nestedTranslate } from 'utils/strings';
import { Loading } from 'components/Loading';
import { updateOnboarding } from '../../redux/actions/onboarding';
import { onboardingStatus } from 'utils/enums/onboardingStatus';

const KYCIFrame = React.lazy(() => import('./KYCIFrame'));

const APPROVED = 'Approved';
const NEVER_SUBMITTED = 'Never Submitted';
const PENDING = 'Pending';
const REJECTED = 'Rejected';
const REQUESTINFO = 'Request Info';

// const StyledList = styled.ul`
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   margin-left: 0.75em;
// `;

// const StyledListItem = styled.li`
//   ::before {
//     content: '•';
//     ${props => `color: ${props.color}`};
//     font-weight: bold;
//     width: 0.75em;
//     margin-left: -0.75em;
//     display: inline-block;
//   }
// `;

// const KYCTipsMessage = ({ t: translate, status }) => {
//   const t = nestedTranslate(translate, 'account.accountVerification.tips');
//   const boxColor = 'background-4';
//   const textColor = 'text';
//   const headingColor = 'brand';

//   const tipList = ['vpn', 'public', 'completed'];

//   const renderTip = tip => (
//     <StyledListItem key={tip} color={textColor}>
//       <Text color={textColor}>{t(tip)}</Text>
//     </StyledListItem>
//   );

//   return (
//     <Box background={boxColor} margin={{ bottom: 'small' }}>
//       <Heading color={headingColor} style={{textAlign:"center"}} margin={{ bottom: 'small' }}>
//         {t(status === REJECTED ? 'headerFailed' : 'header')}
//       </Heading>
//       {status === REJECTED && (
//         <Paragraph color={textColor} margin={{ bottom: 'xsmall' }}>
//           {t('errorMessage')}
//         </Paragraph>
//       )}
//       <StyledList color={textColor}>
//         {tipList.map(singleTip => renderTip(singleTip))}
//       </StyledList>
//     </Box>
//   );
// };

class KYCPage extends React.Component {
  renderKycForm() {
    const {
      kyc: { isSumSubIframe },
      t: translate
    } = this.props;
    const t = nestedTranslate(translate, 'account.onboarding.kyc');

    if (isSumSubIframe) {
      return (
        <Suspense fallback={<Loading />}>
          <Paragraph margin={{ bottom: 'medium'}}>
            {t('disclaimer')}
          </Paragraph>
          <KYCIFrame />
        </Suspense>
      );
    }

    return <KYCForm />;
  }

  handleApproved() {
    this.props.updateOnboarding({
      body: {
        isKycApproved: true,
        currentStep: onboardingStatus.COMPLIANCE_QUESTIONNAIRE,
        subtype: onboardingStatus.KYC,
        data: 'Kyc approved',
      },
    });
  }

  createMarkup() {
    const {
      profile: { kycRejectReason },
    } = this.props;

    return { __html: kycRejectReason };
  }

  kycStatus() {
    const {
      profile: { kycStatus },
      t,
    } = this.props;

    switch (kycStatus) {
      case APPROVED:
        return this.handleApproved();
      case NEVER_SUBMITTED:
        return (
          <React.Fragment>
            {/* <KYCTipsMessage status={kycStatus} t={t} /> */}
            {this.renderKycForm()}
          </React.Fragment>
        );
      case PENDING:
        return (
          <Box fill={true} justify="center" align="center">
            <InProgress color="status-ok" size="large" />
            <Heading fontWeight={300} level={2} margin="xsmall">
              {t('account.accountVerification.pending')}
            </Heading>
          </Box>
        );
      case REJECTED:
        return (
          <React.Fragment>
            {/* <KYCTipsMessage status={kycStatus} t={t} />
            {kycRejectReason && (
              <Notification color="danger">
                {t('account.accountVerification.rejectionReason')}:{' '}
                <div dangerouslySetInnerHTML={this.createMarkup()} />
              </Notification>
            )} */}
            {this.renderKycForm()}
          </React.Fragment>
        );
      case REQUESTINFO:
        return <React.Fragment>{this.renderKycForm()}</React.Fragment>;
      default:
        break;
    }
  }

  render() {
    return <React.Fragment>{this.kycStatus()}</React.Fragment>;
  }
}

const mapStateToProps = ({
  exchangeSettings: { settings },
  user: { profile },
}) => ({
  kyc: settings.kyc,
  profile,
});

const mapDispatchToProps = {
  updateOnboarding,
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(KYCPage),
);
