/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, CheckBox } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import { hoverTrustCoApi } from 'api';
import s from '../Documents.module.scss';


const Download = ({ t: translate, isTrustAgree, updateTrustAgree }) => {
  const [trustDocument, setTrustDocument] = useState(null);
  const { onboarding, firstName, middleName, lastName } = useSelector(({ user }) => user.profile);

  const t = nestedTranslate(translate, 'account.onboarding.documents.steps');

  const getDocuments = async () => {
    const doc = await hoverTrustCoApi.authenticatedCall({
      url: 'documents/presignedGetUrl/params?key=documents/Trust_Doc_1.pdf',
      method: 'GET',
    });

    setTrustDocument(doc);
  };

  useEffect(() => {
    getDocuments();
    updateTrustAgree(onboarding.are_documents_signed)
  }, []);

  const onClickTrustAgree = () => {
    updateTrustAgree(!isTrustAgree);
  };


  return (
    <>
      <Box margin={{ top: 'medium' }}>
        <WrappedParagraph base>{t('step2', { name: `${firstName} ${middleName} ${lastName}` })}</WrappedParagraph>
        <WrappedParagraph margin={{ left: 'xsmall' }} base>
          <Box direction="row" margin={{ top: 'small' }}>
            <a
              href="/documentation-files/Hover_Trust_Deed_3.1.23v_no_signatures.pdf"
              target="_blank"
              className={s.link}
            >
              {t('document')}
            </a>
          </Box>
          <Box direction="row" align="center" margin={{ top: 'small' }}>
          </Box>
          <Box direction="row" align="center" margin={{ top: 'small', bottom: 'small' }}>
            <CheckBox checked={isTrustAgree} onChange={onClickTrustAgree} label={t('trustAgree')} />
          </Box>
        </WrappedParagraph>
        {/* <WrappedParagraph margin={{ top: 'medium' }}>
          {t('step3')}
        </WrappedParagraph>
        <WrappedParagraph margin={{ top: 'small' }}>
          {t('step4')}
        </WrappedParagraph> */}
      </Box>
    </>
  );
};

export default withNamespaces()(Download);
