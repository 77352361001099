import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Grid, Heading } from 'grommet';
import s from './Account.module.scss';
import { Button } from 'components/Wrapped';
import downArrow from '../../assets/icons/down-arrow.png';
import { onboardingStatus } from 'utils/enums/onboardingStatus';
import { nestedTranslate } from 'utils';

const NextSteps = ({ t: translate }) => {
  const { onboarding } = useSelector(({ user }) => user.profile);
  const { current_step: currentStep } = onboarding;
  const {
    TWOFA,
    KYC,
    DOCUMENTS,
    QUESTIONNAIRE,
    COMPLIANCE_QUESTIONNAIRE,
    ONBOARDED,
  } = onboardingStatus;

  const [currentStepOrder, setCurrentStepOrder] = useState(0);

  const steps = [
    { order: 0, title: '' },
    { order: 1, title: TWOFA },
    { order: 2, title: KYC },
    { order: 3, title: COMPLIANCE_QUESTIONNAIRE },
    { order: 4, title: DOCUMENTS },
    { order: 5, title: QUESTIONNAIRE },
  ];

  useEffect(() => {
    if (currentStep) {
      const currentOrder = steps.find(({ title }) => title === currentStep)
        .order;
      setCurrentStepOrder(currentOrder);
    }
  }, [currentStep]);

  const buildCircle = ({ title, order }) => {
    if (currentStep === title) return s.activeCircle;
    if (currentStepOrder > order) return s.checked;
    if (currentStepOrder < order) return s.inactiveCircle;
  };

  const t = nestedTranslate(translate, 'account.overview.nextSteps');

  return (
    <>
      <Heading level={3}>{t('title')}</Heading>
      <Box
        direction="row"
        border={{ color: 'brand', size: 'small' }}
        margin={{ vertical: 'small' }}
        pad="small"
      >
        <Grid
          rows={['auto']}
          columns={['xxsmall', 'auto']}
          fill="horizontal"
          gap="small"
          areas={[
            { name: 'side', start: [0, 0], end: [0, 0] },
            { name: 'main', start: [1, 0], end: [1, 0] },
          ]}
        >
          <Box gridArea="side" align="center" style={{ position: 'relative' }}>
            {steps.map(({ title, order }, i) => (
              <Fragment key={i}>
                <div className={buildCircle({ title, order })} />
                <div className={s.separatingLine} />
              </Fragment>
            ))}
            <div className={s.circle} />
            <div className={s.separatingLine} style={{ height: '15px' }} />
            <img src={downArrow} alt="down-arrow" className={s.downArrow} />
          </Box>
          <Box gridArea="main">
            <div className={s.content}>
              <Heading level={5}>{t('steps.createAccount.title')}</Heading>
            </div>
            <div className={s.contentWithMT}>
              <Heading level={5}>{t('steps.enable2Fa.title')}</Heading>
              {currentStep === TWOFA && (
                <Link to="/account/onboarding">
                  <Button color="primary">{t('steps.enable2Fa.button')}</Button>
                </Link>
              )}
            </div>
            <div className={s.contentWithMT}>
              <Heading level={5}>{t('steps.kyc.title')}</Heading>
              {currentStep === KYC && (
                <Link to="/account/onboarding">
                  <Button color="primary">{t('steps.kyc.button')}</Button>
                </Link>
              )}
            </div>
            <div className={s.contentWithMT}>
              <Heading level={5}>
                {t('steps.answerQuestionnaire.compliance.title')}
              </Heading>
              {currentStep === COMPLIANCE_QUESTIONNAIRE && (
                <Link to="/account/onboarding">
                  <Button color="primary">
                    {t('steps.answerQuestionnaire.compliance.button')}
                  </Button>
                </Link>
              )}
            </div>
            <div className={s.contentWithMT}>
              <Heading level={5}>{t('steps.signDocuments.title')}</Heading>
              {currentStep === DOCUMENTS && (
                <Link to="/account/onboarding">
                  <Button color="primary">
                    {t('steps.signDocuments.button')}
                  </Button>
                </Link>
              )}
            </div>
            <div className={s.contentWithMT}>
              <Heading level={5}>
                {t('steps.answerQuestionnaire.knowledge.title')}
              </Heading>
              {currentStep === QUESTIONNAIRE && (
                <Link to="/account/onboarding">
                  <Button color="primary">
                    {t('steps.answerQuestionnaire.knowledge.button')}
                  </Button>
                </Link>
              )}
            </div>
            <div className={s.contentWithMT}>
              <Heading level={5}> {t('steps.fundYourAccount.title')}</Heading>
              {currentStep === ONBOARDED && (
                <Link to="/portfolio/deposits">
                  <Button color="primary">
                    {t('steps.fundYourAccount.button')}
                  </Button>
                </Link>
              )}
            </div>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default withNamespaces()(NextSteps);
