import React, { useMemo, useState } from 'react';
import { CheckBoxGroup } from 'grommet';
import QuestionContainer from './QuestionContainer';
import PropTypes from 'prop-types';

const MultipleChoicesQuestion = ({
  question,
  options,
  initialSelectedOptions = [],
  description = null,
  required = false,
  error = null,
  afterChange,
  ...rest
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions || [],
  );
  const sortedOptions = useMemo(() => {
    return [...options].sort((a, b) => a.order - b.order);
  }, [options]);

  return (
    <QuestionContainer
      question={question}
      description={description}
      required={required}
      error={error}
      {...rest}
    >
      <CheckBoxGroup
        value={selectedOptions}
        onChange={({ value }) => {
          setSelectedOptions(value);
          afterChange(value);
        }}
        options={sortedOptions}
        valueKey={'id'}
        labelKey={'text'}
        style={{ fontSize: 17, fontWeight: 'normal' }}
      />
    </QuestionContainer>
  );
};

MultipleChoicesQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  initialSelectedOptions: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  afterChange: PropTypes.func,
};

export default MultipleChoicesQuestion;
