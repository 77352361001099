import React from 'react';
import { withNamespaces } from 'react-i18next';
import s from './Modal.module.scss';
import { Box, Button, Heading, Modal, Paragraph } from 'components/Wrapped';
import exclamationMark from '../../assets/icons/exclamation-mark.png';

const WithdrawalWarningModal = ({ t, showModal, setShowModal, onConfirm }) => {
  return (
    <Modal show={showModal} toggleModal={setShowModal} width="large" pad="none">
      <Box pad="medium">
        <Box align="center" pad={{ horizontal: 'small' }}>
          <img
            src={exclamationMark}
            className={s.exclamationMark}
            alt="Exclamation mark"
          />
        </Box>
        <Box>
          <Heading level={4} textAlign="center">
            {t('modals.withdrawalWarning.title')}
          </Heading>
          <Paragraph margin={{ top: 'small' }} textAlign="center">
            {t('modals.withdrawalWarning.subtitle')}
          </Paragraph>
        </Box>
        <Box direction="row" justify="center">
          <Button color="primary" onClick={onConfirm}>
            {t('buttons.accept')}
          </Button>
          <Button
            margin={{ horizontal: 'xsmall' }}
            color="primary"
            primary={false}
            onClick={() => setShowModal(false)}
          >
            {t('buttons.cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default withNamespaces()(WithdrawalWarningModal);
