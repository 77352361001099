import React from 'react';
import { Box, Text } from 'grommet';
import { QuestionnaireDescription } from './index';
import PropTypes from 'prop-types';

const QuestionContainer = ({
  question,
  description = null,
  required = false,
  error = null,
  children,
  margin = { bottom: '18px' },
  ...rest
}) => {
  return (
    <Box pad="xsmall" gap="14px" margin={margin} {...rest}>
      {/* Question label with asterisk if required */}
      <Box direction="row" align="center" gap="xsmall">
        <Text as="span" style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {question}{' '}
          {required && (
            <Text as={'span'} color="status-critical">
              *
            </Text>
          )}
        </Text>{' '}
        {/* Asterisk if required */}
      </Box>
      <Box margin={{ start: 'small' }}>
        {description && (
          <QuestionnaireDescription>{description}</QuestionnaireDescription>
        )}
      </Box>
      {/* Render the question-specific component (Dropdown, RadioGroup, etc.) */}
      <div
        style={{
          marginLeft: '4px',
        }}
      >
        {children}
      </div>
      {/* Error message */}
      {error && (
        <Text
          margin={{ left: '4px' }}
          color="status-critical"
          size="small"
          style={{ fontWeight: 'medium' }}
        >
          {error}
        </Text>
      )}
    </Box>
  );
};

QuestionContainer.propTypes = {
  question: PropTypes.string.isRequired,
  description: PropTypes.string || null,
  required: PropTypes.bool,
  error: PropTypes.string || null,
  children: PropTypes.node.isRequired,
  margin: PropTypes.object,
};

export default QuestionContainer;
