import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { CheckBox } from 'grommet';
import { Box, Button, Heading, Modal, Paragraph } from 'components/Wrapped';

const CheckboxModal = ({
  t,
  isOpen,
  toggle,
  title,
  children,
  checkboxLabel,
  onConfirm,
  signed,
}) => {
  // const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const confirm = () => {
    if (isChecked) {
      onConfirm();
      toggle();
    }
  };

  // const handleScroll = ({ target }) => {
  //   const { scrollHeight, scrollTop, clientHeight } = target;
  //   const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight) <= 1);
  //   if (bottom) setHasScrolledToBottom(true);
  // };

  return (
    <Modal
      show={isOpen}
      toggleModal={toggle}
      width="large"
      margin={{ top: 'medium' }}
      pad="none"
    >
      <Box>
        <Heading size="medium" style={{ textAlign: 'center' }}>
          {title}
        </Heading>
      </Box>
      <Box
        margin={{ top: 'xsmall' }}
        style={{ overflow: 'auto' }}
      // onScroll={handleScroll}
      >
        {children}
      </Box>
      {!signed && (
        <>
          <Box>
            <CheckBox
              checked={isChecked}
              label={checkboxLabel}
              onChange={event => setIsChecked(event.target.checked)}
              style={{ fontSize: 14 }}
            // disabled={!hasScrolledToBottom}
            />
          </Box>
          <Box direction="row" justify="center" align="center">
            <Button
              color="primary"
              onClick={confirm}
              disabled={!isChecked}
            >
              {t('buttons.continue')}
            </Button>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default withNamespaces()(CheckboxModal);
