import React from 'react';
import { set } from 'lodash';
import { useQuery } from 'react-query';
import { exchangeApi } from 'api';
import { PageWrap } from 'components/Containers';
import { Loading } from 'components/Loading';
import { Box } from 'components/Wrapped';
import { useMediaQuery } from 'react-responsive';
import InstaTradeV2Form from './InstaTradeV2Form';

const useInstaTradePairs = () => {
  return useQuery('instaTradePairs', () => exchangeApi.getInstaTradePairs(), {
    refetchInterval: 30000,
  });
};

const InstaTrade = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { isLoading, data } = useInstaTradePairs();

  if (isLoading) {
    return (
      <PageWrap>
        <Loading />
      </PageWrap>
    );
  }

  let tradingPairsByCurrency = {};

  data?.data.forEach(({ baseCurrency, quoteCurrency, ...rest }) => {
    const singleData = {
      receiveCurrency: quoteCurrency,
      paymentCurrency: baseCurrency,
      ...rest,
    };

    set(tradingPairsByCurrency, `${quoteCurrency}.${baseCurrency}`, singleData);
  });

  return (
    <PageWrap>
      <Box pad="none" justify="center" align="center" gap="medium">
        <Box
          pad="none"
          width={isMobile ? { max: '100%' } : '550px'}
          responsive={false}
        >
         {tradingPairsByCurrency && <InstaTradeV2Form tradingPairsByCurrency={tradingPairsByCurrency} />}
        </Box>
      </Box>
    </PageWrap>
  );
};

export default InstaTrade;
