import { signalRDataHub, signalRUrl } from 'setup';
// eslint-disable-next-line
import signalR from 'signalr';

const { $ } = window;

export default class SignalRConnection {
  constructor() {
    this.afterStart = [];
    this.subscriptions = {};
    this.connection = $.hubConnection(signalRUrl);
    this.dataTickerHubProxy = this.connection.createHubProxy(signalRDataHub);
    this.dataTickerHubProxy.logging = true;
    this.initialize();
  }

  initialize() {
    this.connection.start().done(this.afterStarted);

    // When a reconnect event happens, use the reconnect method
    // This ensures that previous connections will never get stale
    this.connection.reconnected(this.reconnect);

    window.addEventListener('offline', this.stop);

    window.addEventListener('online', this.reconnect);
  }

  stop = () => {
    this.connection.stop();
  };

  start = () => {
    return this.connection.start();
  };

  afterStarted = () => {
    // Sometimes an invoke will be called before the connection has initialized
    // `afterStart` collects functions to run and runs them on initialization
    if (this.afterStart.length) {
      this.afterStart.forEach(singleFn => singleFn());
      this.afterStart = [];
    }
  };

  // Stops the current connection (if it is active) then starts a new one
  // invoking all subscription items
  reconnect = () => {
    this.stop();
    this.start().done(() => {
      this.afterStarted();
      Object.values(this.subscriptions).forEach(singleSubcription => {
        this.invoke(...singleSubcription);
      });
    });
  };

  on(message, callback) {
    return this.dataTickerHubProxy.on(message, callback);
  }

  off(message, callback) {
    return this.dataTickerHubProxy.off(message, callback);
  }

  // Subscribe will cache an `invoke` message to be possibly used later
  subscribe(...args) {
    this.subscriptions[args[0]] = args;

    return this.invoke(...args);
  }

  // Unsubscribe will send an `invoke` message and remove any related subscription
  // e.g. `leaveGroup` should remove the subscription for `joinGroup`
  unsubscribe(invokeMessage, subscription) {
    if (this.subscriptions[subscription]) {
      delete this.subscriptions[subscription];
    }

    return this.invoke(invokeMessage);
  }

  invoke(...args) {
    if (this.connection.state === 1) {
      return this.dataTickerHubProxy.invoke(...args);
    }

    this.afterStart = [
      ...this.afterStart,
      () => {
        this.dataTickerHubProxy.invoke(...args);
      },
    ];
  }
}

// export const socket = new SignalRConnection();

// window.socket = socket;
