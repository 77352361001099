import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, Heading } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';

const Instructions = ({ t: translate }) => {
  const t = nestedTranslate(translate, 'account.onboarding.documents');

  return (
    <>
      <Box
        margin={{ vertical: 'medium' }}
        pad="small"
        direction="row"
        justify="between"
        align="center"
        border={{ color: 'brand', size: 'small' }}
      >
        <Heading level={4}>{t('instructions.title')}</Heading>
      </Box>
      <Box margin={{ vertical: 'xsmall' }}>
        <WrappedParagraph style={{ fontSize: 17 }}>{t('instructions.text')}</WrappedParagraph>
      </Box>

    </>
  );
};

export default withNamespaces()(Instructions);
