import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Box } from 'grommet';
import { Down, Logout, Services, User, UserSettings } from 'grommet-icons';
import { nestedTranslate } from 'utils';
import { Modal, Menu } from 'components/Wrapped';
import { Settings } from 'containers/Settings';
import { useLogout } from '../../../utils/hooks';

function AccountDropdown({ t: translate, history, profile }) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { logout } = useLogout();

  const t = nestedTranslate(translate, 'navbar.links');

  const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen);

  const itemsToRender = [
    {
      label: `${profile.firstName} ${profile.lastName}`,
      style: { padding: '10px' },
      onClick: () => {
        history.push('/account');
      },
    },
    {
      label: (
        <Box pad="small" alignSelf="center" margin={{ right: 'medium' }}>
          Account
        </Box>
      ),
      icon: (
        <Box pad="small">
          <User size="medium" />
        </Box>
      ),
      style: { borderTop: '2px white solid' },
      onClick: () => {
        history.push('/account');
      },
    },
    {
      label: (
        <Box pad="small" alignSelf="center">
          {t('settings')}
        </Box>
      ),
      icon: (
        <Box pad="small">
          <Services size="medium" />
        </Box>
      ),
      onClick: () => {
        setIsSettingsOpen(true);
      },
    },
    {
      label: (
        <Box pad="small" alignSelf="center">
          {t('logout')}
        </Box>
      ),
      icon: (
        <Box pad="small">
          <Logout size="medium" />
        </Box>
      ),
      style: { borderTop: '2px white solid' },
      onClick: () => logout(),
    },
  ];

  const iconToRender = (
    <>
      <UserSettings size="medium" />
      <Down size="small" />
    </>
  );

  return (
    <>
      <Menu
        plain
        label=""
        dropBackground="background"
        justifyContent="end"
        icon={iconToRender}
        items={itemsToRender}
      />

      {/* Settings Modal */}
      <Modal
        position="center"
        show={isSettingsOpen}
        toggleModal={() => toggleSettings()}
      >
        <Settings />
      </Modal>
    </>
  );
}

const mapStateToProps = ({ user }) => ({
  profile: user.profile,
});

export default withRouter(
  withNamespaces()(connect(mapStateToProps)(AccountDropdown)),
);
