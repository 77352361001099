import { hoverTrustCoApi } from '../../api';
import { updateOnboarding } from './onboarding';
import { onboardingStatus } from '../../utils/enums/onboardingStatus';

export const UPDATE_SELECTED_OPTIONS = 'UPDATE_SELECTED_OPTIONS';
export const UPDATE_CURRENT_SECTION = 'UPDATE_CURRENT_SECTION';
export const RESET_QUESTIONNAIRE = 'RESET_QUESTIONNAIRE';
export const LOAD_QUESTIONNAIRE_FROM_DRAFT = 'LOAD_QUESTIONNAIRE_FROM_DRAFT';

export const loadQuestionnaireFromDraft = draftAnswers => ({
  type: LOAD_QUESTIONNAIRE_FROM_DRAFT,
  payload: draftAnswers,
});

export const updateSelectedOptions = selectedOptions => ({
  type: UPDATE_SELECTED_OPTIONS,
  payload: selectedOptions,
});

export const updateCurrentSectionIndex = index => ({
  type: UPDATE_CURRENT_SECTION,
  payload: index,
});

export const resetQuestionnaire = () => ({
  type: RESET_QUESTIONNAIRE,
  payload: null,
});

export const submitQuestionnaire = ({ questionnaireId, answers }) => {
  return async dispatch => {
    const body = {
      answers: answers,
    };
    try {
      await hoverTrustCoApi.authenticatedCall({
        url: `questionnaires/${questionnaireId}/answers`,
        method: 'POST',
        data: body,
      });
      dispatch(
        updateOnboarding({
          body: {
            currentStep: onboardingStatus.DOCUMENTS,
            subtype: onboardingStatus.COMPLIANCE_QUESTIONNAIRE,
            data: null,
          },
        }),
      );
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  };
};
