import React from 'react';
import { Markdown } from 'grommet';

import { Paragraph, Heading, Box, Text } from 'components/Wrapped';
import ExternalLink from '../../../../components/Helpers/ExternalLink';

const UnorderedList = ({ children, ...rest }) => {
  return (
    <Box pad="none" margin={{ horizontal: '18px' }}>
      <Text textAlign="start">
        <ul {...rest}>{children}</ul>
      </Text>
    </Box>
  );
};

const OrderedList = ({ children, ...rest }) => {
  return (
    <Box pad="none" margin={{ horizontal: '18px' }}>
      <Text textAlign="start">
        <ol {...rest}>{children}</ol>
      </Text>
    </Box>
  );
};

const ListItem = ({ children, ...rest }) => {
  const [listItem, ...restItems] = children;

  return (
    <Text>
      <li {...rest}>
        <Text textAlign="start">{listItem}</Text>
        {restItems}
      </li>
    </Text>
  );
};

const QuestionnaireDescription = ({ children }) => {
  return (
    <Markdown
      className={'questionnaireDescription'}
      components={{
        a: {
          component: ExternalLink,
        },
        p: {
          component: Paragraph,
          props: {
            margin: { vertical: 'small' },
            textAlign: 'start',
            style: {
              fontSize: '16px',
            },
          },
        },
        h1: {
          component: Heading,
          props: {
            level: 1,
          },
        },
        h2: {
          component: Heading,
          props: {
            level: 2,
          },
        },
        h3: {
          component: Heading,
          props: {
            level: 3,
          },
        },
        h4: {
          component: Heading,
          props: {
            level: 4,
          },
        },
        h5: {
          component: Heading,
          props: {
            level: 5,
          },
        },
        ul: {
          component: UnorderedList,
          props: {
            style: {
              listStyleType: 'disc',
              marginTop: '6px',
              fontSize: '16px',
              fontWeight: 'normal',
            },
          },
        },
        ol: {
          component: OrderedList,
          props: {
            style: {
              marginTop: '6px',
              fontSize: '16px',
            },
          },
        },
        li: {
          component: ListItem,
          props: {
            style: {
              marginBottom: '12px',
              fontSize: '16px',
            },
          },
        },
      }}
    >
      {children}
    </Markdown>
  );
};

export default QuestionnaireDescription;
