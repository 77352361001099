import React, { useMemo, useState } from 'react';
import { Select, ThemeContext } from 'grommet';
import QuestionContainer from './QuestionContainer';
import PropTypes from 'prop-types';

const DropDownQuestion = ({
  question,
  options,
  description = null,
  initialSelectedOption = null,
  required = false,
  error = null,
  afterChange,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(
    initialSelectedOption || null,
  );

  const sortedOptions = useMemo(() => {
    return [...options].sort((a, b) => a.order - b.order);
  }, [options]);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const handleOptionChange = ({ option }) => {
    setSelectedOption(option);
    afterChange(option);
  };
  return (
    <QuestionContainer
      question={question}
      description={description}
      required={required}
      error={error}
      {...rest}
    >
      <ThemeContext.Extend
        value={{
          select: {
            control: {
              extend: `background-color: var(--background-2);`,
            },
          },
        }}
      >
        {/* Dropdown component */}
        <Select
          id="DropDownQuestion"
          options={filteredOptions.length ? filteredOptions : sortedOptions}
          labelKey={'text'}
          value={selectedOption}
          onChange={handleOptionChange}
          onClose={() => setFilteredOptions([])}
          placeholder="Select an option"
          searchPlaceholder="Search"
          dropHeight="xlarge"
          onSearch={text => {
            const regex = new RegExp(text, 'i');
            setFilteredOptions(
              options.filter(option => option.text.match(regex)),
            );
          }}
          size="medium"
        />
      </ThemeContext.Extend>
    </QuestionContainer>
  );
};

DropDownQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  description: PropTypes.string || null,
  initialSelectedOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  required: PropTypes.bool,
  error: PropTypes.string || null,
  afterChange: PropTypes.func.isRequired,
};

export default DropDownQuestion;
