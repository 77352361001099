import SignalRConnection from './signalr';
import WebSocketConnection from './websocket';
import { websocketUrl } from 'setup';

class SocketConnection {
  constructor() {
    const socket = websocketUrl ? new WebSocketConnection() : new SignalRConnection();
    
    return socket;
  }
}

export const socket = new SocketConnection();

window.socket = socket;