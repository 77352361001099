import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import s from './Modal.module.scss';
import { Box, Button, Heading, Paragraph, Modal } from 'components/Wrapped';
import padlock from '../../assets/img/padlock.svg';

const NonTradablePairModal = ({ t }) => (
  <Modal show={true} keepOpen={true} pad="none">
    <Box pad="medium" align="center">
      <Box pad={{ horizontal: 'small' }}>
        <img src={padlock} className={s.padlock} alt="Padlock" />
      </Box>
      <Box align="center">
        <Heading level={4} textAlign="center">
          {t('modals.nonTradableAssets.title')}
        </Heading>
        <Paragraph margin={{ top: 'small' }}>
          {t('modals.nonTradableAssets.subtitle')}
        </Paragraph>
      </Box>
      <Box>
        <Link to="/portfolio">
          <Button color="primary">{t('buttons.backToPortfolio')}</Button>
        </Link>
      </Box>
    </Box>
  </Modal>
);

export default withNamespaces()(NonTradablePairModal);
