import axios from 'axios';
import { store } from 'redux/store';
import { logOut } from 'redux/actions/profile';
import { updateProfile } from "redux/actions/profile";

export class HoverTrustCoApi {
  constructor({ config }) {
    this.refreshToken = '';
    this.authenticatedInstance = axios.create(config);
    this.authenticatedInstance.interceptors.response.use(
      this.handleSuccess,
      this.handleError,
    );
  }

  authenticatedCall = async ({
    url,
    method = 'GET',
    data,
    responseType
  }) => {


    return this.authenticatedInstance({
      url,
      method,
      data,
      responseType
    });
  };

  handleSuccess(response) {
    const { data } = response;

    if (response.status === 201 || response.status === 200) {
      return data;
    }

    return Promise.reject(data);
  }

  handleError = async error => {
    const originalConfig = error.config;
    if (!originalConfig.url.includes('auth/login') && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const data = await this.authenticatedCall({
            url: `auth/accessToken`, method: 'post', data: {
              refreshToken: this.refreshToken,
            }
          });

          if (data.status === 404) {
            return Promise.reject(data);
          }
          originalConfig.headers.Authorization = `Bearer ${data.token}`;
          this.setAuthentication(data.token);
          this.setRefreshToken(data.refreshToken);
          store.dispatch(updateProfile({
            trustcoAuthorization: data.token,
            trustcoRefreshToken: data.refreshToken,
          }));
          this.authenticatedInstance.defaults.headers[
            'Authorization'
          ] = `Bearer ${data.token}`;
          return this.authenticatedInstance(originalConfig);
        } catch (_error) {
          store.dispatch(logOut());
          return Promise.reject(_error);
        }
      }
      return Promise.reject(error);

    }
    return error.response;
  }


  setAuthentication(token) {
    this.authenticatedInstance.defaults.headers['Authorization'] = token?.startsWith("Bearer") ? token : `Bearer ${token}`;
  }

  setRefreshToken(refreshToken) {
    this.refreshToken = refreshToken;
  }

  cleanAuthentication() {
    this.authenticatedInstance.defaults.headers['Authorization'] = '';
  }

  getAuthentication() {
    return {
      token: this.authenticatedInstance.defaults.headers['Authorization'],
      refreshToken: this.refreshToken
    };
  }

  clearAuthentication() {
    delete this.authenticatedInstance.defaults.headers['Authorization'];
  }
}