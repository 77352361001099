import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, TextArea, TextInput, ThemeContext } from 'grommet';
import QuestionContainer from './QuestionContainer';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const TextEntryQuestion = ({
  question,
  initialValue = null,
  description = null,
  required = false,
  placeholder = 'Enter here',
  singleLine = true,
  error = null,
  afterChange,
  ...rest
}) => {
  const [text, setText] = useState(initialValue || '');
  const afterChangeRef = useRef(afterChange);
  const debouncedAfterChange = useRef(
    debounce(newValue => {
      afterChangeRef.current(newValue);
    }, 300),
  ).current;

  useEffect(() => {
    setText(initialValue || '');
  }, [initialValue]);

  const handleChange = event => {
    const newValue = event.target.value;
    setText(newValue);
    debouncedAfterChange(newValue);
  };
  return (
    <QuestionContainer
      width="large"
      question={question}
      description={description}
      required={required}
      error={error}
      {...rest}
    >
      <ThemeContext.Extend
        value={
          singleLine
            ? {
                textInput: {
                  extend: `background-color: var(--background-2);`,
                },
              }
            : {
                textArea: {
                  extend: `background-color: var(--background-2);`,
                },
              }
        }
      >
        <Box margin={{ bottom: '8px' }}>
          <Text style={{ fontSize: '17px', fontWeight: '600' }}>
            {placeholder}
          </Text>
        </Box>
        {singleLine ? (
          <TextInput
            value={text}
            onChange={handleChange}
            size="medium"
            width="medium"
          />
        ) : (
          <TextArea value={text} onChange={handleChange} size="large" />
        )}
      </ThemeContext.Extend>
    </QuestionContainer>
  );
};

TextEntryQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  singleLine: PropTypes.bool,
  error: PropTypes.string,
  afterChange: PropTypes.func.isRequired,
};

export default TextEntryQuestion;
