import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Heading } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import { Instructions } from './Instructions';
import { Download } from './Download';
import { Upload } from './Upload';
import { PendingApproval } from './Notifications/PendingApproval';
import { Rejected } from './Notifications/Rejected';
import { TrustCosts } from './TrustCosts';

const Documents = ({ t: translate }) => {
  const t = nestedTranslate(translate, 'account.onboarding.documents');
  const { onboarding } = useSelector(({ user }) => user.profile);
  const { are_documents_signed: areDocumentsSigned } = onboarding;
  const { are_documents_approved: areDocumentsApproved } = onboarding;
  const { are_documents_rejected: areDocumentsRejected } = onboarding;
  const [isTrustAgree, setIsTrustAgree] = useState(false);


  const isPendingApproval =
    areDocumentsSigned && !areDocumentsApproved && !areDocumentsRejected;
  const isRejected = areDocumentsRejected;
  return (
    <>
      <Heading level={3} as="div">{t('title')}</Heading>
      {isRejected && <Rejected />}
      {isPendingApproval && <PendingApproval />}
      {!isPendingApproval && (
        <>
          <Instructions />
          <Box
            margin={{ vertical: 'medium' }}
            pad="small"
            direction="row"
            justify="between"
            align="center"
            border={{ color: 'brand', size: 'small' }}
          >
            <Heading level={4} as="div">{t('steps.title')}</Heading>
          </Box>

          <TrustCosts />
          <Download isTrustAgree={isTrustAgree} updateTrustAgree={setIsTrustAgree} />
          <Upload isTrustAgree={isTrustAgree} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(Documents);
