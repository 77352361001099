import { store } from 'redux/store';

export const calculatePayment = ({ value, rate, commission }) => {
  return -(100 * value) / (rate * (commission - 100));
};

export const isTradingPairLoaded = tradingPair =>
  Object.keys(tradingPair).length;

export const getSelectValue = value => ({ value, label: value });

export const buildCurrencies = currencies =>
  currencies.sort().map(singleKey => getSelectValue(singleKey));

//iterates over object and find unique keys (i.e. unique assets)
export const sortAllowedPaymentCurrencies = tradingPairsByCurrency => {
  const allowedPaymentCurrencies = [];

  for (let key in tradingPairsByCurrency) {
    var innerObject = tradingPairsByCurrency[key];
    for (let key2 in innerObject) {
      var draftPaymentCurrency = innerObject[key2].paymentCurrency;
      if (allowedPaymentCurrencies.indexOf(draftPaymentCurrency) === -1) {
        allowedPaymentCurrencies.push(draftPaymentCurrency);
      }
    }
  }

  return allowedPaymentCurrencies;
};

export const checkIfAssetTradable = asset => {
  const { tradableAssets } = store?.getState().user.profile;

  const found = tradableAssets?.find(
    tradableAsset => tradableAsset.modulus_id === asset,
  );
  return found ? true : false;
};

export const checkIfPairTradable = (receiveCurrency, paymentCurrency) => {
  let isTradable = true;
  const isPaymentCurrencyTradable = checkIfAssetTradable(paymentCurrency);

  const isReceiveCurrencyTradable = checkIfAssetTradable(receiveCurrency);

  if (!isPaymentCurrencyTradable || !isReceiveCurrencyTradable) {
    isTradable = false;
  }

  return isTradable;
};

export const removeNonAllowedAssets = tradingPairs => {
  const allowedPairs = tradingPairs.filter(pair =>
    checkIfAssetTradable(pair.quoteCurrency) ? pair : null,
  );
  return allowedPairs;
};

export const filterNonAllowedAssets = currencies => {
  const { tradableAssets } = store.getState().user.profile;

  var matchingAssets = currencies.filter(({ value }) =>
    tradableAssets.some(({ modulus_id }) => value === modulus_id),
  );

  return matchingAssets;
};
