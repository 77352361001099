import React, { useCallback, useMemo, useState } from 'react';
import { RadioButtonGroup } from 'grommet';
import QuestionContainer from './QuestionContainer';
import PropTypes from 'prop-types';

const SingleChoiceQuestion = ({
  question,
  options,
  initialSelectedOptionId = null,
  description = null,
  required = false,
  error = null,
  afterChange,
  ...rest
}) => {
  const optionsMap = options.reduce((acc, option) => {
    acc[option.id] = option;
    return acc;
  }, {});
  const [selectedOption, setSelectedOption] = useState(
    initialSelectedOptionId ? optionsMap[initialSelectedOptionId]?.text : '',
  );
  const sanitizedOptions = useMemo(() => {
    const sortedOptions = [...options].sort((a, b) => a.order - b.order);
    return sortedOptions.map(({ id, text }) => ({
      id,
      label: text,
      value: text,
    }));
  }, [options]);

  const handleChange = useCallback(
    ({ id, value }) => {
      setSelectedOption(value);
      afterChange(optionsMap[id]);
    },
    [afterChange, optionsMap],
  );
  return (
    <QuestionContainer
      question={question}
      description={description}
      required={required}
      error={error}
      {...rest}
    >
      <RadioButtonGroup
        name={question}
        options={sanitizedOptions}
        value={selectedOption}
        onChange={({ target }) => handleChange(target)}
        style={{ fontSize: 17, fontWeight: 'normal' }}
      />
    </QuestionContainer>
  );
};

SingleChoiceQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      order: PropTypes.number,
    }),
  ).isRequired,
  initialSelectedOptionId: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  afterChange: PropTypes.func.isRequired,
};

export default SingleChoiceQuestion;
