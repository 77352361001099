import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Box, Heading, Spinner } from 'grommet';
import { Button } from 'components/Wrapped';
import { nestedTranslate } from 'utils/strings';
import { triggerModalOpen, triggerToast } from 'redux/actions/ui';
import {
  DropDownQuestion,
  MultipleChoicesQuestion,
  QuestionnaireDescription,
  SingleChoiceQuestion,
  TextEntryQuestion,
} from './Questions';
import {
  resetQuestionnaire,
  submitQuestionnaire,
  updateCurrentSectionIndex,
  updateSelectedOptions,
} from 'redux/actions/questionnaire';
import ConfirmCancelQuestionnaireModal from '../../../containers/Modals/ConfirmCancelQuestionnaireModal';
import {
  CloudDoneIcon,
  CloudOfflineIcon,
} from '../../../assets/icons/CloudIcon';
import useQuestionnaire from '../../../hooks/useQuestionnaire';

const ComplianceQuestionnaire = ({ t: translate }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const t = nestedTranslate(
    translate,
    'account.onboarding.questionnaire.compliance',
  );

  const [
    showCancelQuestionnaireModal,
    setShowCancelQuestionnaireModal,
  ] = useState(false);

  const selectedOptions = useSelector(
    state => state.questionnaire.selectedOptions,
  );

  const currentSectionIndex = useSelector(
    state => state.questionnaire.currentSectionIndex,
  );

  const {
    questionnaire,
    questionsMap,
    setHasUnsavedChanges,
    hasUnsavedChanges,
    saveDraft,
    deleteDraft,
    saveDraftMessage,
    isSavingDraft,
    draftSaveStatus,
    isConditionMet,
    validateSection,
    validateQuestion,
  } = useQuestionnaire(t, dispatch, selectedOptions);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const currentSection = questionnaire?.sections[currentSectionIndex];

  const updateQuestionnaire = useCallback(
    ({
      question,
      selectedOptionId,
      responseText = '',
      isConfirmation = false,
    }) => {
      if (Array.isArray(selectedOptionId)) {
        const selectedOptionsIds = selectedOptionId.map(optionId => ({
          optionId,
          responseText: responseText,
        }));
        dispatch(
          updateSelectedOptions({
            questionId: question.id,
            answer: selectedOptionsIds,
          }),
        );
      } else {
        dispatch(
          updateSelectedOptions({
            questionId: isConfirmation ? `${question.id}_confirm` : question.id,
            answer: {
              optionId: selectedOptionId,
              responseText,
            },
          }),
        );
      }
      setHasUnsavedChanges(true);
    },
    [dispatch, selectedOptions],
  );

  // Handle unsaved changes when user navigates away
  useEffect(() => {
    const handleBeforeUnload = e => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleNavigation = direction => {
    if (direction === 'next') {
      const errors = validateSection(currentSection);
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
      setValidationErrors({});
      saveDraft();
      const nextSectionIndex = currentSectionIndex + 1;
      dispatch(
        updateCurrentSectionIndex(
          nextSectionIndex < questionnaire.sections.length
            ? nextSectionIndex
            : currentSectionIndex,
        ),
      );
    } else if (direction === 'previous') {
      const previousSectionIndex = currentSectionIndex - 1;
      dispatch(
        updateCurrentSectionIndex(
          previousSectionIndex >= 0 ? previousSectionIndex : 0,
        ),
      );
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSubmit = () => {
    const validationErrors = validateSection(currentSection);
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }
    setIsSubmitting(true);
    const answers = Array.from(selectedOptions.entries())
      // remove confirmation questions from the answers
      .filter(([questionId]) => !questionId.endsWith('_confirm'))
      .flatMap(([questionId, answer]) => {
        if (Array.isArray(answer)) {
          return answer.map(({ optionId, responseText = '' }) => ({
            questionId,
            optionId,
            responseText,
          }));
        } else {
          // If the answer is an object
          const { optionId, responseText = '' } = answer;
          return [{ questionId, optionId, responseText }];
        }
      })
      .filter(answer => {
        const question = questionsMap.get(answer.questionId);
        const conditions = question.modifiers.conditions;
        return conditions ? isConditionMet(conditions) : true;
      });

    dispatch(
      submitQuestionnaire({
        questionnaireId: questionnaire.id,
        answers,
      }),
    )
      .then(() => {
        setIsSubmitting(false);
        setHasUnsavedChanges(false);
        dispatch(triggerToast('questionnaireSent', 'success'));
        history.push('/account');
        dispatch(resetQuestionnaire());
        window.scrollTo(0, 0);
      })
      .catch(error => {
        dispatch(triggerModalOpen('somethingWentWrong'));
        setIsSubmitting(false);
        console.error('Failed to submit questionnaire:', error);
      });
  };

  const renderQuestion = question => {
    const { text, modifiers, options, description } = question;
    const requiresConfirmation = modifiers.confirm;
    const selectedOption = selectedOptions.get(question.id);

    if (modifiers.conditions && !isConditionMet(modifiers.conditions)) {
      return null;
    }

    const { validationError, confirmationError } = validateQuestion(
      question,
      selectedOption,
      validationErrors[question.id] ||
        validationErrors[`${question.id}_confirm`],
    );

    switch (modifiers.type) {
      case 'single_choice':
        return (
          <li key={question.id}>
            <SingleChoiceQuestion
              question={text}
              description={description}
              required={modifiers.required}
              options={options}
              initialSelectedOptionId={
                selectedOptions.get(question.id)?.optionId
              }
              error={validationError}
              afterChange={selectedOption => {
                updateQuestionnaire({
                  question,
                  selectedOptionId: selectedOption.id,
                });
              }}
            />
          </li>
        );
      case 'multiple_choices':
        return (
          <li key={question.id}>
            <MultipleChoicesQuestion
              question={text}
              description={description}
              required={modifiers.required}
              initialSelectedOptions={
                selectedOption?.map(option => option.optionId) || []
              }
              options={options}
              error={validationError}
              afterChange={selectedOptionsIds => {
                updateQuestionnaire({
                  question,
                  selectedOptionId: selectedOptionsIds,
                });
              }}
            />
          </li>
        );
      case 'text':
        return (
          <li key={question.id}>
            <TextEntryQuestion
              question={text}
              description={description}
              initialValue={
                selectedOptions.get(question.id)?.responseText || ''
              }
              required={modifiers.required}
              singleLine={modifiers.type === 'text'}
              placeholder={options[0].text}
              error={validationError}
              afterChange={text =>
                updateQuestionnaire({
                  question,
                  selectedOptionId: options[0].id,
                  responseText: text,
                })
              }
            />
            {requiresConfirmation && (
              <TextEntryQuestion
                question={modifiers.confirm.text}
                initialValue={
                  selectedOptions.get(`${question.id}_confirm`)?.responseText ||
                  ''
                }
                required={modifiers.required}
                singleLine={modifiers.type === 'text'}
                placeholder={modifiers.confirm.description}
                error={confirmationError}
                afterChange={text =>
                  updateQuestionnaire({
                    question,
                    selectedOptionId: options[0].id,
                    responseText: text,
                    isConfirmation: true,
                  })
                }
              />
            )}
          </li>
        );
      case 'dropdown':
        return (
          <li key={question.id}>
            <DropDownQuestion
              question={text}
              description={description}
              required={modifiers.required}
              options={options}
              initialSelectedOption={question.options?.find(
                option => option.id === selectedOption?.optionId,
              )}
              error={validationError}
              afterChange={selectedOption => {
                updateQuestionnaire({
                  question,
                  selectedOptionId: selectedOption.id,
                });
              }}
            />
          </li>
        );
      default:
        return null;
    }
  };

  if (questionnaire === null) {
    return (
      <Box
        direction="row"
        justify="center"
        align="center"
        alignContent="center"
        height="50vh"
        width="full"
      >
        <Spinner size="medium" alignSelf="center" />
      </Box>
    );
  }

  const renderDraftStatus = () => {
    let backgroundColor = '';
    let textColor = '';
    let iconColor = '';
    let message = saveDraftMessage;
    let iconComponent = null;
    let gap = 'xsmall';

    if (isSavingDraft) {
      backgroundColor = '#f0f0f0';
      textColor = '#333';
      iconColor = '#333';
      message = 'Saving draft...';
      iconComponent = <Spinner size="xsmall" color={iconColor} />;
      gap = 'small';
    } else if (draftSaveStatus === 'success') {
      backgroundColor = '#e6e0fd';
      textColor = '#6558f5';
      iconColor = '#6558f5';
      iconComponent = (
        <CloudDoneIcon width="24px" height="24px" fill={iconColor} />
      );
    } else if (draftSaveStatus === 'error') {
      backgroundColor = '#fdeaea';
      textColor = '#a4262c';
      iconColor = '#a4262c';
      iconComponent = (
        <CloudOfflineIcon width="24px" height="24px" fill={iconColor} />
      );
    } else {
      return null;
    }

    return (
      <Box
        direction="row"
        align="center"
        gap={gap}
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        background={backgroundColor}
        round="small"
        alignContent="center"
        justify="center"
        height="40px"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          minWidth: '120px',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          {iconComponent}
        </div>
        <span
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: textColor,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            marginLeft: '4px',
            textAlign: 'center',
            flex: 1,
          }}
        >
          {message}
        </span>
      </Box>
    );
  };

  const handleResetQuestionnaire = async () => {
    await deleteDraft();
    dispatch(resetQuestionnaire());
    setShowCancelQuestionnaireModal(false);
    history.push('/account');
  };

  return (
    <>
      {showCancelQuestionnaireModal && (
        <ConfirmCancelQuestionnaireModal
          show={true}
          title={t('cancelConfirmation.title')}
          message={t('cancelConfirmation.message')}
          closeButtonText={t('cancelConfirmation.buttons.close')}
          confirmButtonText={t('cancelConfirmation.buttons.confirm')}
          onClose={() => setShowCancelQuestionnaireModal(false)}
          confirm={handleResetQuestionnaire}
        ></ConfirmCancelQuestionnaireModal>
      )}
      <Box
        direction="row"
        align="center"
        justify="between"
        margin={{
          bottom: 'small',
        }}
      >
        <Heading level={2}>{t('title')}</Heading>
        {renderDraftStatus()}
      </Box>
      <progress
        className={'questionnaireProgressBar'}
        style={{
          width: '100%',
          marginBottom: '8px',
        }}
        max={questionnaire.sections.length}
        value={currentSectionIndex}
      />
      <Heading
        margin={{
          bottom: 'small',
        }}
        level={3}
        color={'dark-5'}
      >
        {currentSection?.title || ''}
      </Heading>
      {currentSection.description && (
        <Box pad="small">
          <QuestionnaireDescription>
            {currentSection.description}
          </QuestionnaireDescription>
        </Box>
      )}
      <Box pad="none" margin={{ vertical: 'small' }}>
        {currentSection ? (
          <ul style={{ fontSize: 20, fontWeight: 'bold' }}>
            {currentSection.questions.map(question => renderQuestion(question))}
          </ul>
        ) : (
          <p>{t('loadingQuestionnaire')}</p>
        )}
      </Box>
      <Box direction="row" justify="center" pad="small">
        <Button
          margin={{ horizontal: 'xsmall' }}
          color="primary"
          primary={false}
          onClick={() => {
            setShowCancelQuestionnaireModal(true);
          }}
          disabled={isSubmitting}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={() => handleNavigation('previous')}
          disabled={currentSectionIndex === 0 || isSubmitting}
          margin={{ horizontal: 'xsmall' }}
          color="primary"
        >
          {t('buttons.back')}
        </Button>
        {currentSectionIndex === questionnaire.sections.length - 1 ? (
          <Button
            onClick={onSubmit}
            margin={{ horizontal: 'xsmall' }}
            color="primary"
            loading={isSubmitting}
          >
            {t('buttons.submit')}
          </Button>
        ) : (
          <Button
            onClick={() => handleNavigation('next')}
            disabled={currentSectionIndex === questionnaire.sections.length - 1}
            margin={{ horizontal: 'xsmall' }}
            color="primary"
          >
            {t('buttons.next')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default withNamespaces()(ComplianceQuestionnaire);
