import React, { Component } from 'react';

import { store } from "redux/store";
import { logOut } from 'redux/actions/profile';

import { IdleTimer } from 'components/Helpers/IdleTimer'
import SessionModal from 'containers/Modals/SessionModal';

class IdleTimerLayout extends Component {
  constructor(props) {
    super(props);

    //idleTimer
    this.idleTimer = null
    this.onPrompt = this.onPrompt.bind(this)
    this.onIdle = this.onIdle.bind(this)

    this.state = {
      isConnected: true,
      changeIcon: 0,
      delayTimer: 500,
      showModal: false,
      remainingTime: 0,
    };
  }

  onPrompt() {
    this.setState({ showModal: true, remainingTime: this.idleTimer.getRemainingTime() / 1000 });
  }

  onIdle() {
    this.setState({ showModal: false });
    store.dispatch(logOut());
  }

  componentDidMount() {
    this.idleInterval = null
    this.idleTimer?.start()

    this.idleInterval = setInterval(() => {
      if (this.state.showModal) {
        this.setState(state => ({ ...state, remainingTime: this.idleTimer.getRemainingTime() / 1000 }));
      }

    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.indleInterval);
    this.indleInterval = null;
  }

  render() {

    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          timeout={Number(process.env.REACT_APP_TIMEOUT)}
          promptTimeout={1000 * 20}
          onPrompt={this.onPrompt}
          onIdle={this.onIdle}
          startOnMount />
        <SessionModal
          show={this.state.showModal}
          confirm={() => {
            this.setState(state => {
              return { ...state, showModal: false };
            })
            this.idleTimer.reset()
          }
          }
          remainingTime={Math.trunc(this.state.remainingTime)}
        /></>
    );
  }
}


export default IdleTimerLayout;