import React, { useState } from 'react';
import s from '../Documents.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Box, CheckBox } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import { Paragraph } from 'components/Wrapped';
import { updateOnboarding } from 'redux/actions/onboarding';
import { onboardingStatus } from 'utils/enums/onboardingStatus';

const TermsAndConditions = ({ t: translate }) => {
  const dispatch = useDispatch();
  const t = nestedTranslate(translate, 'account.onboarding.documents');

  const { onboarding } = useSelector(({ user }) => user.profile);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const { are_terms_conditions_signed: areTermsConditionsSigned } = onboarding;

  const onClickTermsAndConditions = () => {
    dispatch(
      updateOnboarding({
        body: {
          areTermsConditionsSigned: true,
          subtype: onboardingStatus.DOCUMENTS,
          data: 'Terms and conditions signed',
        },
      }),
    );
  };

  const onClickPrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(true);
  };

  return (
    <Box margin={{ top: 'medium' }}>
      <WrappedParagraph base>{t('steps.step5')}</WrappedParagraph>
      <Box direction="row" align="center" margin={{ top: 'small' }}>
        {!areTermsConditionsSigned && (
          <CheckBox checked={false} onChange={onClickTermsAndConditions} />
        )}
        <WrappedParagraph base>
          <a
            href="/documentation-files/HoverTrusts - Terms_and_Conditions_2023.pdf"
            target="_blank"
            style={{ marginLeft: '10px' }}
          >
            Terms and conditions
          </a>
        </WrappedParagraph>
        {areTermsConditionsSigned && (
          <div className={s.checked} style={{ marginLeft: '6px' }} />
        )}
      </Box>
      <Box direction="row" align="center" margin={{ top: 'small' }}>
        
        <a
          href="/documentation-files/HoverTrusts Privacy Policy.pdf"
          target="_blank"
          className={s.link}
          style={{ marginLeft: '10px' }}
          onClick={onClickPrivacyPolicy}
        >
          Privacy policy
        </a>
        {isPrivacyPolicyOpen && (
          <div className={s.checked} style={{ marginLeft: '6px' }} />
        )}
      </Box>
      <Paragraph margin={{ top: 'small' }} base>
        {t('termsAndConditions.disclaimer')}
      </Paragraph>
    </Box>
  );
};

export default withNamespaces()(TermsAndConditions);
