import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { Box, Button, Modal, Paragraph } from 'components/Wrapped';

const SessionModal = ({ remainingTime, confirm, show, t }) => {
  return (
    <Modal
      heading={t('modals.session.title')}
      show={show}
      pad="none"
    >
      <Box pad="large"><Paragraph margin={{ top: 'small' }}>{t('modals.session.subtitle') + remainingTime + ' seconds'}</Paragraph></Box>
      <Box
        direction="row"
        pad="small"
        justify="end"
        background="background-4"
        gap="small"
      >
        <Button color="primary" onClick={confirm}>
          {t('modals.session.button')}
        </Button>
      </Box>
    </Modal>
  );
};

SessionModal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default withNamespaces()(SessionModal);
