import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Box, Heading, Button } from 'components/Wrapped';
import { Loading } from 'components/Loading';
import PropTypes from 'prop-types';
import CompleteOnboardingModal from 'containers/Modals/CompleteOnboardingModal';

const RequiresFullOnboarding = ({ t, children }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    isProfileLoading,
    profile: { isOnboarded },
  } = useSelector(({ user }) => user);

  const { pathname } = useLocation();

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    setShowModal(true);
  }, [pathname]);

  return (
    <Fragment>
      {isOnboarded ? (
        children
      ) : !isProfileLoading ? (
        <>
          <CompleteOnboardingModal
            showModal={showModal}
            setShowModal={toggleModal}
          />
          <Box align="center" justify="center" height="medium">
            <Heading level={3} size="medium">
              {t('account.accountVerification.requiresFullOnboarding')}
            </Heading>
            <Link to="/account/onboarding">
              <Button color="primary" margin={{ vertical: 'small' }}>
                {t('buttons.completeOnboarding')}
                <span
                  style={{
                    fontSize: '1.25em',
                    marginLeft: '10px',
                    cursor: 'pointer',
                  }}
                  className="fas fa-shield-alt"
                />
              </Button>
            </Link>
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};

RequiresFullOnboarding.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withNamespaces()(RequiresFullOnboarding);
