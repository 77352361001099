import React, { useState } from 'react';
import { Box, RadioButtonGroup } from 'grommet';

const Question = ({ question, updateQuestionaire }) => {
  const [value, setValue] = useState('');
  const { options, text } = question;

  const handleChange = ({ question, target }) => {
    const { id, value } = target;
    setValue(value);

    const weight = options.find(option => option.id === id).weight;
    updateQuestionaire({
      question,
      selectedOption: { id, value, weight },
    });
  };

  const sanitizeOptions = options => {
    options.sort((a, b) => a.order - b.order);
    return options.map(({ id, text, weight }) => ({
      id,
      label: text,
      value: text,
      weight,
    }));
  };

  return (
    <Box pad="small" direction="column" gap="small" align="start">
      <label style={{ fontSize: 17 }}>{text}</label>
      <RadioButtonGroup
        name={text}
        options={sanitizeOptions(options)}
        value={value}
        onChange={({ target }) => handleChange({ question, target })}
        style={{ fontSize: 17 }}
      />
    </Box>
  );
};

export default Question;
