import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Box } from 'grommet';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import s from '../Documents.module.scss';
import { updateOnboarding } from 'redux/actions/onboarding';
import { onboardingStatus } from 'utils/enums/onboardingStatus';
import CheckboxModal from 'containers/Modals/CheckboxModal';

const TrustCosts = ({ t: translate }) => {
  const dispatch = useDispatch();
  const t = nestedTranslate(translate, 'account.onboarding.documents');

  const { onboarding } = useSelector(({ user }) => user.profile);

  const [areTrustCostsOpen, setAreTrustCostsOpen] = useState(false);

  const { are_trust_costs_signed: areTrustCostsSigned } = onboarding;

  const onClickTrustCosts = () => {
    dispatch(
      updateOnboarding({
        body: {
          areTrustCostsSigned: true,
          subtype: onboardingStatus.DOCUMENTS,
          data: 'Trust Costs signed',
        },
      }),
    );
  };

  return (
    <Box margin={{ top: 'small' }}>
      <CheckboxModal
        title={t('trustCosts.title')}
        checkboxLabel={<WrappedParagraph style={{ fontSize: 14 }}>
          {t('trustCosts.checkboxLabel')}
        </WrappedParagraph>}
        isOpen={areTrustCostsOpen}
        toggle={() => setAreTrustCostsOpen(!areTrustCostsOpen)}
        onConfirm={onClickTrustCosts}
        signed={areTrustCostsSigned}
      >
        <ul style={{ listStyle: 'inherit' }}>
          {[t('trustCosts.text.fee1'),
          t('trustCosts.text.fee2'),
          t('trustCosts.text.fee3'),
          t('trustCosts.text.fee4'),
          t('trustCosts.text.fee5')].map((el, index) => {
            return <li><WrappedParagraph margin={{ top: index > 0 && 'small' }} base>{el}</WrappedParagraph></li>
          })}
        </ul>

      </CheckboxModal>
      {/* --- */}

      <WrappedParagraph base>{t('steps.step1')}</WrappedParagraph>
      <Box direction="row" align="center" margin={{ top: 'small' }}>
        <div className={s.link} onClick={() => setAreTrustCostsOpen(true)}>
          <WrappedParagraph base>{t('trustCosts.title')}</WrappedParagraph>
        </div>
        {areTrustCostsSigned && <div className={s.checked} />}
      </Box>
    </Box>
  );
};

export default withNamespaces()(TrustCosts);
