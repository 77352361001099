import React from 'react';
import logo from '../../assets/img/logo.svg';

const Logo = ({ style, className }) => {
  return (
    <img
      src={logo}
      className={className}
      style={{ height: '10vh', ...style }}
      alt="Hover Logo"
    />
  );
};

export default Logo;
