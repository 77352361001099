import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { Box, Button, Modal } from 'components/Wrapped';
import { Text } from 'grommet';

const ConfirmCancelQuestionnaireModal = ({
  children,
  confirm,
  onClose,
  show,
  title,
  message,
  closeButtonText,
  confirmButtonText,
  pad = 'large',
}) => {
  return (
    <Modal
      heading={title}
      show={show}
      toggleModal={onClose}
      pad="none"
      width="large"
      position="center"
    >
      <Box pad={'medium'}
      >
        <Text textAlign={'start'} as={'p'} style={{ fontSize: '17px', fontWeight: '600' }}>{message}</Text>
      </Box>
      <Box
        direction="row"
        pad="small"
        justify="end"
        background="background-4"
        gap="small"
      >
        <Button onClick={onClose}>{closeButtonText}</Button>
        <Button color="primary" onClick={confirm}>
          {confirmButtonText}
        </Button>
      </Box>
    </Modal>
  );
};

ConfirmCancelQuestionnaireModal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ConfirmCancelQuestionnaireModal.defaultProps = {
  title: 'Confirm',
  closeButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
};

export default withNamespaces()(ConfirmCancelQuestionnaireModal);
