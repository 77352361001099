import React from 'react';
import { NavbarItem } from 'components/Navbar';
import { withNamespaces } from 'react-i18next';
import { nestedTranslate } from 'utils/strings';
import { CircleQuestion } from 'grommet-icons';

const AuthenticatedNav = ({
  topNav,
  t: translate,
  sideNav = false,
  enableDSSO,
}) => {
  const t = nestedTranslate(translate, 'navbar.links');

  return (
    <>
      {enableDSSO && (
        <NavbarItem to="/derivatives">{t('derivatives')}</NavbarItem>
      )}

      <NavbarItem to="/orders">{t('orders')}</NavbarItem>
      <NavbarItem to="/portfolio">{t('wallet')}</NavbarItem>

      {sideNav && (
        <>
          <NavbarItem to="/account">{t('account')}</NavbarItem>
          <NavbarItem to="/settings">{t('settings')}</NavbarItem>
        </>
      )}

      {/* <NavbarItem>
        <CircleQuestion size="medium" />{' '}
      </NavbarItem> */}
    </>
  );
};

export default withNamespaces()(AuthenticatedNav);
