import { hoverTrustCoApi } from 'api';
import { loadProfile } from './profile';
import { triggerModalOpen } from './ui';

export const updateOnboarding = ({ body }) => {
  return async (dispatch, getState) => {
    const {
      user: {
        profile: {
          trustcoId: userId,
          onboarding: { id },
        },
      },
    } = getState();
    body = { ...body, userId };
    try {
      await hoverTrustCoApi.authenticatedCall({
        url: `onboardings/${id}`,
        method: 'PATCH',
        data: body,
      });
      dispatch(loadProfile());
    } catch (e) {
      dispatch(triggerModalOpen('somethingWentWrong'));
    }
  };
};
