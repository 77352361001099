import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Formik, useField, ErrorMessage, useFormikContext } from 'formik';
import { withNamespaces } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useMutation, queryCache } from 'react-query';
import * as Yup from 'yup';

import { exchangeApi, authenticatedInstance } from 'api';
import { nestedTranslate } from 'utils';
import {
  Box,
  Button,
  Message,
  Text,
  Modal,
  Paragraph,
} from 'components/Wrapped';
import {
  Form,
  IconOption,
  IconValue,
  FormField,
  TextField,
  CheckBox,
} from 'components/Form';
import { ReactSelect } from 'components/Form/SelectField';
import { RequestWithdrawalCode } from 'pages/Wallet';
import { triggerToast } from 'redux/actions/ui';
import styles from './AddressBook.module.scss';

const mapStateToProps = ({ exchangeSettings: { currencySettings } }) => ({
  currencySettings,
});

const CurrencySelect = connect(mapStateToProps)(
  ({ onChange, currencySettings, currency, t }) => {
    const [option, setOption] = useState();
    const [, meta] = useField('currency');
    const options = useMemo(
      () =>
        _.sortBy(Object.values(currencySettings), 'fullName')
          .filter(({ walletType }) => !walletType.includes('Fiat'))
          .map(({ shortName: currency, fullName }) => ({
            value: currency,
            label: `${fullName} (${currency})`,
          })),
      [currencySettings],
    );

    const handleChange = useCallback(
      ({ value, label }) => {
        setOption({ value, label });
        onChange({
          currency: value,
          currencySettings: _.get(currencySettings, value),
        });
      },
      [currencySettings, onChange],
    );

    useEffect(() => {
      const defaultValue = _.find(options, { value: currency });
      if (defaultValue) {
        handleChange(defaultValue);
      }
    }, [currency, options]);

    return (
      <Box pad="none" gap="xsmall" margin={{ bottom: 'small' }}>
        <Text size="small" weight="bold">
          {t('currency')}
        </Text>
        <ReactSelect
          options={options}
          components={{ Option: IconOption, SingleValue: IconValue }}
          onChange={handleChange}
          value={option}
          margin="none"
        />
        {meta.touched && meta.error && (
          <Box pad={{ horizontal: 'small' }} align="start">
            <Text color="status-error" size="xsmall">
              <ErrorMessage name="currency" />
            </Text>
          </Box>
        )}
      </Box>
    );
  },
);

const AddressFormOtp = ({ onSuccess, t }) => {
  const { values } = useFormikContext();
  const { currency, address, memo } = values;

  const enabled = currency && address;

  return (
    <RequestWithdrawalCode
      messageText={t('otpMessage')}
      successText={t('otpSuccessMessage')}
      buttonText={t('requestOtp')}
      requestMethod={exchangeApi.requestAddressBookOtp}
      requestData={{ currency, address, memo }}
      background="background-3"
      disabled={!enabled}
      handleSuccess={onSuccess}
    />
  );
};

export const AddAddressForm = withNamespaces()(
  ({ t: translate, currency, handleSuccess }) => {
    const gAuthEnabled = useSelector(
      ({ auth: { gAuthEnabled } }) => gAuthEnabled,
    );
    const { email } = useSelector(({ user }) => user.profile);
    const t = nestedTranslate(translate, 'wallet.addressBook');
    const [currencySettings, setCurrencySettings] = useState();
    const [error, setError] = useState();
    const [otpSent, setOtpSent] = useState(false);
    const [tempToken, setTempToken] = useState();
    const [emailtoken, setEmailToken] = useState();
    const [smstoken, setSMSToken] = useState();

    const [mutate] = useMutation(
      data => {
        setError();
        const { authCode, ...restData } = data;
        const authCodeData = gAuthEnabled
          ? { authCode }
          : { tempToken, otp: authCode };
        return exchangeApi.addToAddressBook({
          emailtoken,
          ...authCodeData,
          ...restData,
        });
      },
      {
        onSuccess: response => {
          if (response.status === 'Success') {
            queryCache.invalidateQueries('addressBook');
            if (handleSuccess) {
              handleSuccess();
            }
          } else {
            setError(response.message);
          }
        },
      },
    );

    const handleOtpSuccess = ({ temp_token }) => {
      setOtpSent(true);
      setTempToken(temp_token);
    };

    const validationSchema = () => {
      return Yup.object().shape({
        currency: Yup.string().required(),
        label: Yup.string().required(),
        address: Yup.string().required(),
        authCode: Yup.string().required(),
        attest: Yup.bool().oneOf([true], translate('forms.walletWithdrawal.attest')),
      });
    };

    const requestOTPCode = async (
      address,
      currency,
      memo,
      otpType,
    ) => {
      try {
        const { data } = await authenticatedInstance({
          url: '/api/request-otp-addressbook',
          method: 'POST',
          data: {
            Address: address,
            Currency: currency,
            DT_Memo: memo,
            type: otpType,
          },
        });

        if (data.status === 'Success') {
          if (otpType === 'sms') {
            setSMSToken(data.data.temp_token);
          } else if (otpType === 'email') {
            setEmailToken(data.data.temp_token);
          }

          triggerToast(data.status, 'success');
        } else {
          triggerToast(data.message, 'error');
        }
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <React.Fragment>
        {error && (
          <Message background="background-2" margin={{ vertical: 'small' }}>
            {translate(`messages.${error}`)}
          </Message>
        )}
        <Formik
          initialValues={{
            currency: '',
            label: '',
            address: '',
            memo: '',
            authCode: '',
            attest: false,
          }}
          validationSchema={validationSchema()}
          onSubmit={values => mutate(values)}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <CurrencySelect
                onChange={({
                  currency,
                  currencySettings: newCurrencySettings,
                }) => {
                  setFieldValue('currency', currency);
                  setCurrencySettings(newCurrencySettings);
                }}
                currency={currency}
                t={t}
              />
              <FormField name="label" label={t('label')}>
                <TextField />
              </FormField>
              <FormField name="address" label={t('newAddress')}>
                <TextField />
              </FormField>
              {!_.isEmpty(currencySettings?.addressSeparator) && (
                <FormField name="memo" label={t('tag')}>
                  <TextField />
                </FormField>
              )}
              <Box pad="none" className={styles.btnTxtBox}>
                <FormField
                  name="emailotp"
                  label={translate(
                    'forms.walletWithdrawal.emailandSMSVerificationCode.placeholder',
                  )}
                >
                  <TextField
                    type="text"
                    placeholder={translate(
                      'forms.walletWithdrawal.emailandSMSVerificationCode.placeholder',
                    )}
                    className={styles.modifiedTextField}
                    margin={{ bottom: '0px' }}
                  />
                </FormField>
                <Box pad="none" style={{ flexGrow: 1 }}>
                  <Button
                    color="primary"
                    type="button"
                    style={{ padding: 0 }}
                    disabled={
                      _.isEqual(values.address, '') ||
                      _.isEqual(values.currency, '')
                    }
                    onClick={() =>
                      requestOTPCode(
                        values.address,
                        values.currency,
                        values.memo,
                        'email',
                      )
                    }
                    className={styles.modifiedBtnAddon}
                  >
                    {translate('forms.walletWithdrawal.emailOtpButton')}
                  </Button>
                </Box>
              </Box>
              <div style={{ marginBottom: '20px' }}>
                {translate(
                  'forms.walletWithdrawal.emailVerificationMsg',
                  { email },
                )}
              </div>
              {!gAuthEnabled && (
                <AddressFormOtp onSuccess={handleOtpSuccess} t={t} />
              )}
              <FormField
                name="authCode"
                label={
                  gAuthEnabled ? translate('forms.common.gAuth') : t('otpCode')
                }
              >
                <TextField disabled={gAuthEnabled ? false : !otpSent} />
              </FormField>
              <CheckBox name="attest" label={t('attest')} style={{ padding: 0 }} />
              <Button color="primary" type="submit">
                {translate('buttons.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  },
);

export const AddAddressModal = withNamespaces()(
  ({ t: translate, currency }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen);

    const t = nestedTranslate(translate, 'wallet.addressBook');

    return (
      <React.Fragment>
        <Button color="primary" onClick={toggleModal}>
          {t('addAddress')}
        </Button>
        <Modal show={isOpen} toggleModal={toggleModal}>
          <AddAddressForm handleSuccess={toggleModal} currency={currency} />
        </Modal>
      </React.Fragment>
    );
  },
);

export const AddAddress = withNamespaces()(({ t }) => {
  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      background="background-2"
    >
      <Paragraph>{t('wallet.addressBook.description')}</Paragraph>
      <div>
        <AddAddressModal />
      </div>
    </Box>
  );
});
