import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormField, NumberInput } from 'components/Form';
import { Box, Text } from 'components/Wrapped';
import { numberParser } from 'utils';
import {
  calculatePayment,
  checkIfAssetTradable,
  isTradingPairLoaded,
} from 'containers/Tables/utils';
import NonTradablePairModal from 'containers/Modals/NonTradablePairModal';

const InstaTradeFieldsV2 = ({
  tradingPair,
  tradablePairByModulus,
  paymentSelect,
  receiveSelect,
  t,
}) => {
  const {
    setFieldValue,
    values: { paymentAmount },
  } = useFormikContext();

  const {
    commission,
    rate,
    minLimit,
    maxLimit,
    paymentCurrency,
    receiveCurrency,
  } = tradingPair;

  const [isReceiveCurrencyTradable, setIsReceiveCurrencyTradable] = useState(
    true,
  );

  const checkIfReceiveCurrencyTradable = () => {
    const isTradable = checkIfAssetTradable(receiveCurrency);
    setIsReceiveCurrencyTradable(isTradable);
  };

  const handleValueUpdate = useCallback(
    (name, value) => {
      if (name === 'paymentAmount') {
        const subTotal = rate * value;
        const fee = (commission / 100) * subTotal;
        const total = subTotal - fee;
        setFieldValue('receiveAmount', total);
      }

      if (name === 'receiveAmount') {
        const paymentValue = calculatePayment({ value, rate, commission });
        setFieldValue('paymentAmount', paymentValue);
      }
    },
    [setFieldValue, commission, rate],
  );

  const handleChange = useCallback(
    e => {
      const {
        target: { name, value },
      } = e;
      const newValue = numberParser.parse(value);

      handleValueUpdate(name, newValue);
    },
    [handleValueUpdate],
  );

  useEffect(() => {
    if (isTradingPairLoaded(tradingPair)) checkIfReceiveCurrencyTradable();
    if (paymentAmount) handleValueUpdate('paymentAmount', paymentAmount);
  }, [handleValueUpdate, tradingPair]);

  const buildHelpLabel = () => {
    if (!tradablePairByModulus) return '';

    return t('spendBetween', {
      min: minLimit,
      max: maxLimit,
      currency: paymentCurrency,
    });
  };
  return (
    <>
      {!isReceiveCurrencyTradable && <NonTradablePairModal />}
      <Box pad="none" gap="small">
        <FormField
          name="paymentAmount"
          label={t('payment')}
          help={buildHelpLabel()}
        >
          <NumberInput
            name="paymentAmount"
            addonEnd={{ content: paymentSelect, pad: 'none', width: '150px' }}
            inputOnChange={handleChange}
            margin="none"
          />
        </FormField>
        <div>
          <FormField name="receiveAmount" label={t('receive')}>
            <NumberInput
              name="receiveAmount"
              addonEnd={{ content: receiveSelect, pad: 'none', width: '150px' }}
              inputOnChange={handleChange}
              margin="none"
            />
          </FormField>
          <Text size="xsmall" margin={{ top: 'xsmall' }}>
            {t('feeText')}
          </Text>
        </div>
      </Box>
    </>
  );
};

export default InstaTradeFieldsV2;
