import { triggerToast } from '../redux/actions/ui';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hoverTrustCoApi } from '../api';
import { loadQuestionnaireFromDraft } from '../redux/actions/questionnaire';

const useQuestionnaire = (t, dispatch, selectedOptions) => {
  const [questionnaire, setQuestionnaire] = useState(null);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [saveDraftMessage, setSaveDraftMessage] = useState('');
  const [draftSaveStatus, setDraftSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [questionsMap, setQuestionsMap] = useState(new Map());
  const userId = useSelector(state => state.user.profile.trustcoId);

  // validation
  const isConditionMet = conditions => {
    if (!conditions || conditions.length === 0) {
      return true;
    }

    return conditions.every(condition => {
      const { questionId, optionId } = condition;
      const parentQuestionOptions = selectedOptions.get(questionId);
      const parentQuestion = questionsMap.get(questionId);

      if (!parentQuestion) {
        return false;
      }

      // Recursively check parent conditions
      if (parentQuestion.modifiers.conditions) {
        const parentConditionsMet = isConditionMet(
          parentQuestion.modifiers.conditions,
        );
        if (!parentConditionsMet) {
          return false;
        }
      }

      // Check if the selected options for the parent question include the required optionId
      if (Array.isArray(parentQuestionOptions)) {
        return parentQuestionOptions.some(
          selectedOption => selectedOption.optionId === optionId,
        );
      }

      return (
        parentQuestionOptions && parentQuestionOptions.optionId === optionId
      );
    });
  };

  const validateSection = section => {
    const errors = {};
    section.questions.forEach(question => {
      const selectedOption = selectedOptions.get(question.id);
      const conditions = question.modifiers.conditions;
      if (conditions && !isConditionMet(conditions)) {
        return {};
      }
      const { validationError, confirmationError } = validateQuestion(
        question,
        selectedOption,
        true,
      );
      if (validationError) {
        errors[question.id] = validationError;
      }
      if (confirmationError) {
        errors[`${question.id}_confirm`] = confirmationError;
      }
    });

    return errors;
  };

  const validateQuestion = (
    question,
    selectedOption,
    checkRequired = false,
  ) => {
    const modifiers = question.modifiers;
    let validationError = '';
    let confirmationError = '';

    if (modifiers.confirm) {
      const confirmation = selectedOptions.get(`${question.id}_confirm`);
      const shouldCheckConfirmation =
        confirmation?.responseText || checkRequired;
      if (shouldCheckConfirmation) {
        if (!confirmation || !confirmation.responseText) {
          confirmationError =
            modifiers.confirm.emptyMessage || t('confirmationRequired');
        } else if (confirmation.responseText !== selectedOption.responseText) {
          confirmationError =
            modifiers.confirm.invalidMessage || 'Confirmation does not match';
        }
      }
    }

    const requiredMessage = modifiers.requiredMessage || t('required');
    if (checkRequired) {
      if (modifiers.required) {
        if (modifiers.type === 'text' && !selectedOption?.responseText) {
          return { validationError: requiredMessage, confirmationError };
        } else if (modifiers.type === 'multiple_choices') {
          if (!selectedOption || selectedOption.length === 0) {
            return { validationError: requiredMessage, confirmationError };
          }
        } else if (!selectedOption) {
          return { validationError: requiredMessage, confirmationError };
        }
      }
    }
    // Check regex validation for text fields
    if (modifiers.type === 'text' && modifiers.validations) {
      const validation = modifiers.validations.find(
        val => val.type === 'regex',
      );
      if (validation && selectedOption?.responseText) {
        const regex = new RegExp(validation.value);
        if (!regex.test(selectedOption.responseText)) {
          validationError = validation.message;
        }
      }
    }
    return { validationError, confirmationError };
  };

  const saveDraft = async () => {
    if (questionnaire === null || userId === null) {
      return;
    }
    if (!hasUnsavedChanges) {
      return;
    }
    setIsSavingDraft(true);
    setDraftSaveStatus('saving');
    setSaveDraftMessage(t('draftStatus.saving'));

    const draftEntries = Array.from(selectedOptions.entries()).filter(
      ([questionId, selectedOption]) => {
        const question = questionsMap.get(questionId);
        if (!question) {
          const isConfirmation = questionId.endsWith('_confirm');
          return !!isConfirmation;
        }

        const { validationError, confirmationError } = validateQuestion(
          question,
          selectedOption,
          true,
        );
        return !validationError && !confirmationError;
      },
    );

    const data = draftEntries.flatMap(([questionId, answer]) => {
      if (Array.isArray(answer)) {
        return answer.map(({ optionId, responseText = '' }) => ({
          questionId,
          optionId,
          responseText,
        }));
      } else {
        const { optionId, responseText = '' } = answer;
        return [{ questionId, optionId, responseText }];
      }
    });
    try {
      await hoverTrustCoApi.authenticatedCall({
        url: `questionnaires/${questionnaire.id}/draft`,
        method: 'POST',
        data: {
          answers: data,
        },
      });
      setIsSavingDraft(false);
      setSaveDraftMessage(t('draftStatus.saved'));
      setDraftSaveStatus('success');
      setHasUnsavedChanges(false);
    } catch (error) {
      setIsSavingDraft(false);
      setSaveDraftMessage(t('draftStatus.error'));
      setDraftSaveStatus('error');
    }
  };

  const loadDraft = async questionnaireId => {
    if (questionnaireId === null || userId === null) {
      return;
    }
    try {
      const draft = await hoverTrustCoApi.authenticatedCall({
        url: `questionnaires/${questionnaireId}/draft`,
        method: 'GET',
      });
      setSaveDraftMessage(t('draftStatus.restored'));
      setDraftSaveStatus('success');
      return draft;
    } catch (error) {
      console.error('Failed to load draft:', error);
      return null;
    }
  };

  const deleteDraft = async () => {
    if (questionnaire === null || userId === null) {
      return;
    }
    try {
      await hoverTrustCoApi.authenticatedCall({
        url: `questionnaires/${questionnaire.id}/draft`,
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Failed to delete draft:', error);
    }
  };

  const loadQuestionnaire = async () => {
    try {
      const fetchedQuestionnaire = await hoverTrustCoApi.authenticatedCall({
        url: 'questionnaires/compliance',
        method: 'GET',
      });
      const newQuestionsMap = new Map();
      fetchedQuestionnaire.sections.forEach(section => {
        section.questions.forEach(question => {
          newQuestionsMap.set(question.id, question);
        });
      });
      setQuestionsMap(newQuestionsMap);

      const draft = await loadDraft(fetchedQuestionnaire.id);
      if (draft && selectedOptions.size === 0) {
        dispatch(loadQuestionnaireFromDraft(draft));
      }
      setQuestionnaire(fetchedQuestionnaire);
    } catch (error) {
      dispatch(triggerToast('questionnaireLoadingError', 'error'));
      console.error('Failed to load questionnaire:', error);
    }
  };

  useEffect(() => {
    loadQuestionnaire().catch(error => {
      dispatch(triggerToast('questionnaireLoadingError', 'error'));
    });
  }, []);

  return {
    questionnaire,
    questionsMap,
    userId,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    saveDraft,
    deleteDraft,
    saveDraftMessage,
    isSavingDraft,
    draftSaveStatus,
    isConditionMet,
    validateSection,
    validateQuestion,
  };
};

export default useQuestionnaire;
