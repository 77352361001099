import React from 'react';
import { withNamespaces } from 'react-i18next';

import { Box, Heading, Paragraph } from 'components/Wrapped';

class SignupSuccess extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <Box align="center">
        <Heading level={2}>{t('forms.signUp.success.heading')}</Heading>
        <Paragraph>{t('forms.signUp.success.message')}</Paragraph>
        <Paragraph
          textAlign="center"
          margin={{ top: '10px' }}
          style={{ fontSize: '12px' }}
        >
          {t('forms.signUp.success.disclaimer')}
        </Paragraph>
      </Box>
    )
  }
}

export default withNamespaces()(SignupSuccess);