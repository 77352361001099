import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';

import { Box } from 'components/Wrapped';
import { AnalyticsContainer, NavCoinPicker } from 'containers/TopBar';
import { TabletUp } from 'components/Responsive';

import { withNamespaces } from 'react-i18next';
import LayoutPreference from './LayoutPreference';

const SkinnyBar = ({ t }) => (
  <TabletUp>
    <Box
      pad={{
        horizontal: 'small',
      }}
      round={false}
      direction="row"
      justify="end"
      wrap={true}
      background="skinnyBarBackground"
      flex={false}
      height="50px"
    >
      <Box
        pad="none"
        round={false}
        alignSelf="start"
        align="center"
        flex="grow"
        justify="start"
        direction="row"
        fill="vertical"
      >
        <Route
          path="/"
          render={({ location }) =>
            location.pathname.includes('/trade/') && (
              <React.Fragment>
                <NavCoinPicker />
                <AnalyticsContainer />
              </React.Fragment>
            )
          }
        />
      </Box>
      <LayoutPreference />
    </Box>
  </TabletUp>
);

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

export default withNamespaces()(
  withRouter(connect(mapStateToProps)(SkinnyBar)),
);
