import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { updateOnboarding } from 'redux/actions/onboarding';
import { hoverTrustCoApi } from 'api';
import { Box, Heading } from 'grommet';
import { Button } from 'components/Wrapped';
import { Question } from './Question';
import { nestedTranslate } from 'utils/strings';
import WrappedParagraph from 'components/Wrapped/Paragraph';
import { onboardingStatus } from 'utils/enums/onboardingStatus';
import { triggerToast } from 'redux/actions/ui';

const Questionaire = ({ t: translate }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const t = nestedTranslate(translate, 'account.onboarding.questionnaire.knowledge');

  const [questionnaire, setQuestionnaire] = useState([]);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    loadQuestionnaire();
  }, []);

  const loadQuestionnaire = async () => {
    const questionnaire = await hoverTrustCoApi.authenticatedCall({
      url: `questions`,
      method: 'GET',
    });
    setQuestionnaire(questionnaire);
  };

  const updateQuestionaire = ({ selectedOption, question }) => {
    const draftSelectedOption = buildOnboardingAnswer({
      question,
      selectedOption,
    });

    const optionIndex = selectedOptions.findIndex(
      selectedOption => selectedOption.id === question.id,
    );

    // If answering a new question, add it to the list
    if (optionIndex === -1) {
      setSelectedOption([...selectedOptions, draftSelectedOption]);
      return;
    }

    // If answering an existing question, update it
    const draftOptions = [...selectedOptions];
    draftOptions[optionIndex] = draftSelectedOption;
    setSelectedOption(draftOptions);
  };

  const buildOnboardingAnswer = ({ question, selectedOption }) => ({
    id: question.id,
    text: question.text,
    weight: question.weight,
    answer: {
      id: selectedOption.id,
      text: selectedOption.value,
      weight: selectedOption.weight,
    },
  });

  const onSubmit = () => {
    if (selectedOptions.length < questionnaire.length) {
      setError(t('error.notAllQuestionsAnswered'));
      setTimeout(() => setError(''), 5000);
      return;
    }
    setIsSubmitting(true);
    dispatch(
      updateOnboarding({
        body: {
          isQuestionnaireAnswered: true,
          currentStep: onboardingStatus.ONBOARDED,
          subtype: onboardingStatus.QUESTIONNAIRE,
          data: selectedOptions,
        },
      }),
    );
    dispatch(triggerToast('questionnaireSent', 'success'));
    setIsSubmitting(false);
    history.push('/portfolio');
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Heading level={3}>{t('title')}</Heading>
      <Box pad="small">
        <ol>
          {questionnaire.map(question => (
            <li key={question.id}>
              <Question
                question={question}
                updateQuestionaire={updateQuestionaire}
              />
            </li>
          ))}
        </ol>
      </Box>
      <Box direction="row" justify="center" pad="small">
        <Link to="/account">
          <Button
            margin={{ horizontal: 'xsmall' }}
            color="primary"
            primary={false}
          >
            {t('buttons.cancel')}
          </Button>
        </Link>
        <Button
          onClick={onSubmit}
          margin={{ horizontal: 'xsmall' }}
          color="primary"
          loading={isSubmitting}
        >
          {t('buttons.submit')}
        </Button>
      </Box>
      {error && (
        <WrappedParagraph textAlign="center" color="red">
          {error}
        </WrappedParagraph>
      )}
    </>
  );
};

export default withNamespaces()(Questionaire);
