import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import { formatNumberToPlaces } from 'utils/numbers';
import { PrettyNumberTZ } from 'components/Helpers';
import { Box, Text } from 'components/Wrapped';

const CreateOrderBalance = ({ portfolios, tradingPair, side, t }) => {
  const currency =
    side === 'BUY' ? tradingPair.quoteCurrency : tradingPair.baseCurrency;
  const balance = _.get(portfolios, `${currency}.balance`, 0);

  return (
    <Box
      pad="none"
      direction="row"
      justify="between"
      margin={{ vertical: 'xsmall' }}
    >
      <Text size="inherit">{t('forms.coinBalance')}</Text>
      <Text size="inherit">
        <PrettyNumberTZ number={formatNumberToPlaces(balance)} /> {currency}
      </Text>
    </Box>
  );
};

CreateOrderBalance.propTypes = {
  tradingPair: PropTypes.shape({
    baseCurrency: PropTypes.string,
    quoteCurrency: PropTypes.string,
  }).isRequired,
  side: PropTypes.oneOf(['BUY', 'SELL']),
};

const mapStateToProps = ({ portfolio }) => ({
  portfolios: portfolio.portfolios,
});

export default withNamespaces()(connect(mapStateToProps)(CreateOrderBalance));
